import map from "../assets/images/map.png";
import lightSource from "../assets/images/lightSource.png";
import weyanColorfulImage from "../assets/images/weyanColorfulImage.png";
import shoppingCart from "../assets/images/shoppingCart.png";
import receiptItem from "../assets/images/receiptItem.png";
import emptyWallet from "../assets/images/emptyWallet.png";
import messageFavorite from "../assets/images/messageFavorite.png";
import weyanLogoGif from "../assets/images/gifs/weyanLogo_gif.gif";
import greenShades from "../assets/images/GreenShades.png";
import paymentSection from "../assets/images/PaymentSection.png";
import shopSection from "../assets/images/ShopSection.png";
import marketPlaceSection from "../assets/images/MarketPlaceSection.png";
import chatSection from "../assets/images/ChatSection.png";
import weyanLogoBlackBackground from "../assets/images/weyanLogoBlackBackground.png";
import textBg from "../assets/images/textBg.png";
import coloredBg from "../assets/images/coloredBg.png";
import businessPageMobile from "../assets/images/businessPageMobile.png";
import PersonalPageMobile2 from "../assets/images/PersonalPageMobile2.png";
import bluredImage from "../assets/images/bluredImage.png";
import whiteShades from "../assets/images/whiteShades.png";
import phoneAndCallouts from "../assets/images/PhoneAndCallouts.png";
import flags from "../assets/images/Flags.png";
import egyptFlag from "../assets/images/EgyptFlag.png";
import worldMapBlueDots from "../assets/images/WorldMapBluedots.png";
import needHelp from "../assets/images/NeedHelp.png";
import marketPlace1 from "../assets/images/marketPlace1.png";
import marketPlace2 from "../assets/images/marketPlace2.png";
import marketPlace3 from "../assets/images/marketPlace3.png";
import walletPageImg from "../assets/images/walletPageImg.png";
import walletPageImgMob from "../assets/images/walletPageImgMob.png";
import weyanWallet from "../assets/images/we,yanWallet.png";
import googlePlayAppStore from "../assets/images/GooglePlayAppStore.png";
import IphoneWallet from "../assets/images/IphoneWallet.png";
import banksFrame from "../assets/images/BanksFrame.png";
import paymentShopMarketPlaceChatWithDescription from "../assets/images/PaymentShopMarketPlaceChatWithDescription.png";
import needsHelpMobile from "../assets/images/NeedsHelpMobile.png";
import BolanleBanwo from "../assets/images/stakeholders/BolanleBanwo.png";
import LawrenceEta from "../assets/images/stakeholders/LawrenceEta.png";
import MazenEbeid from "../assets/images/stakeholders/MazenEbeid.png";
import NwamakaUdenigwe from "../assets/images/stakeholders/NwamakaUdenigwe.png";
import SeyeOlusoga from "../assets/images/stakeholders/SeyeOlusoga.png";
import TamerBahgat from "../assets/images/stakeholders/TamerBahgat.png";
import TarikHelmy from "../assets/images/stakeholders/TarikHelmy.png";
import TobiOlusoga from "../assets/images/stakeholders/TobiOlusoga.png";
import TransferChatImage from "../assets/images/TransferChatImage.png";
import TransferChatImageMob from "../assets/images/TransferChatImageMob.png";
import WalletWithTransaction from "../assets/images/WalletWithTransaction.png";
import ConverstaionChatting from "../assets/images/ConverstaionChatting.png";
import HeroWithBackgroundMobile from "../assets/images/HeroWithBackgroundMobile.png";
import PaymentSectionMobile from "../assets/images/PaymentSectionMobile.png";
import ShopSectionMobile from "../assets/images/ShopSectionMobile.png";
import MarketplaceSectionMobile from "../assets/images/MarketplaceSectionMobile.png";
import ChatSectionMobile from "../assets/images/ChatSectionMobile.png";
import nigeriaFlag from "../assets/images/nigeriaFlag.png";
import nigeriaFlagZoomedOut from "../assets/images/nigeriaFlagZoomedOut.png";
import HeroWithBackground from "../assets/images/HeroWithBackground.png";

export const WY_IMAGES = {
  stakeHolders: {
    BolanleBanwo: BolanleBanwo,
    LawrenceEta: LawrenceEta,
    MazenEbeid: MazenEbeid,
    NwamakaUdenigwe: NwamakaUdenigwe,
    SeyeOlusoga: SeyeOlusoga,
    TamerBahgat: TamerBahgat,
    TarikHelmy: TarikHelmy,
    TobiOlusoga: TobiOlusoga,
  },
  businessPageMobile: businessPageMobile,
  WalletWithTransaction: WalletWithTransaction,
  PersonalPageMobile2: PersonalPageMobile2,
  coloredBg: coloredBg,
  textBg: textBg,
  lightSource: lightSource,
  map: map,
  weyanColorfulImage: weyanColorfulImage,
  shoppingCart: shoppingCart,
  receiptItem: receiptItem,
  emptyWallet: emptyWallet,
  messageFavorite: messageFavorite,
  weyanLogoBlackBackground: weyanLogoBlackBackground,
  bluredImage: bluredImage,
  whiteShades: whiteShades,
  marketPlace3: marketPlace3,
  marketPlace2: marketPlace2,
  marketPlace1: marketPlace1,
  phoneAndCallouts: phoneAndCallouts,
  greenShades: greenShades,
  paymentSection: paymentSection,
  shopSection: shopSection,
  marketPlaceSection: marketPlaceSection,
  chatSection: chatSection,
  flags: flags,
  egyptFlag: egyptFlag,
  worldMapBlueDots: worldMapBlueDots,
  needHelp: needHelp,
  walletPageImg: walletPageImg,
  weyanWallet: weyanWallet,
  googlePlayAppStore: googlePlayAppStore,
  IphoneWallet: IphoneWallet,
  banksFrame: banksFrame,
  paymentShopMarketPlaceChatWithDescription:
    paymentShopMarketPlaceChatWithDescription,
  needsHelpMobile: needsHelpMobile,
  walletPageImgMob: walletPageImgMob,
  TransferChatImage: TransferChatImage,
  TransferChatImageMob: TransferChatImageMob,
  ConverstaionChatting: ConverstaionChatting,
  HeroWithBackgroundMobile: HeroWithBackgroundMobile,
  PaymentSectionMobile: PaymentSectionMobile,
  ShopSectionMobile: ShopSectionMobile,
  MarketplaceSectionMobile: MarketplaceSectionMobile,
  ChatSectionMobile: ChatSectionMobile,
  nigeriaFlag: nigeriaFlag,
  nigeriaFlagZoomedOut: nigeriaFlagZoomedOut,
  HeroWithBackground: HeroWithBackground,
};

export const WY_GIFS = {
  weyanLogoGif: weyanLogoGif,
};
