interface TwoHeartsIconProps {
  color?: string;
  width?: number;
  height?: number;
}

export const TwoHeartsIcon: React.FC<TwoHeartsIconProps> = ({
  color = "white",
  width = 16,
  height = 16,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.5352 6.60742C11.7818 6.60742 11.1085 6.97409 10.6885 7.53409C10.2685 6.97409 9.59516 6.60742 8.84182 6.60742C7.56849 6.60742 6.53516 7.64742 6.53516 8.92742C6.53516 9.42076 6.61516 9.88076 6.74849 10.3008C7.40182 12.3741 9.42849 13.6208 10.4285 13.9608C10.5685 14.0074 10.8018 14.0074 10.9418 13.9608C11.9418 13.6208 13.9685 12.3808 14.6218 10.3008C14.7618 9.87409 14.8352 9.42076 14.8352 8.92742C14.8418 7.64742 13.8085 6.60742 12.5352 6.60742Z"
        fill={color}
      />
      <path
        d="M13.832 5.55974C13.832 5.71307 13.6787 5.81307 13.532 5.77307C12.632 5.53974 11.6454 5.73307 10.8987 6.26641C10.752 6.37307 10.552 6.37307 10.412 6.26641C9.88536 5.87974 9.24536 5.66641 8.57203 5.66641C6.85203 5.66641 5.45203 7.07307 5.45203 8.80641C5.45203 10.6864 6.35203 12.0931 7.2587 13.0331C7.30537 13.0797 7.26537 13.1597 7.20537 13.1331C5.38537 12.5131 1.33203 9.93974 1.33203 5.55974C1.33203 3.62641 2.88536 2.06641 4.80536 2.06641C5.94536 2.06641 6.95203 2.61307 7.58536 3.45974C8.22536 2.61307 9.23203 2.06641 10.3654 2.06641C12.2787 2.06641 13.832 3.62641 13.832 5.55974Z"
        fill={color}
      />
    </svg>
  );
};
