import React, { ReactNode, useRef } from "react";
import { motion, useScroll, useTransform } from "framer-motion";

interface ScrollAnimationProps {
  children: ReactNode;
}

const ScrollAnimation: React.FC<ScrollAnimationProps> = ({ children }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      {React.Children.map(children, (child, index) => (
        <Box index={index}>{child}</Box>
      ))}
    </div>
  );
};

interface BoxProps {
  children?: ReactNode;
  index: number;
}

export function Box({ children, index }: BoxProps) {
  const targetRef = useRef<HTMLDivElement>(null);
  const { scrollYProgress } = useScroll({
    target: targetRef,
    offset: ["end end", "end start"],
  });

  const opacity = useTransform(scrollYProgress, [0, 0.5], [1, 0]);
  const scale = useTransform(scrollYProgress, [0, 0.5], [1, 0.8]);

  return (
    <motion.section style={{ opacity }} ref={targetRef}>
      <motion.div style={{ scale }}>{children}</motion.div>
    </motion.section>
  );
}

export default ScrollAnimation;
