interface EyeLashIconProps {
  width?: number;
  height?: number;
}

export const EyeLashIcon: React.FC<EyeLashIconProps> = ({
  width = 16,
  height = 16,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.1796 6.12052C13.9863 5.81386 13.7796 5.52719 13.5663 5.26052C13.3196 4.94719 12.853 4.92052 12.573 5.20052L10.573 7.20052C10.7196 7.64052 10.7463 8.14719 10.613 8.67385C10.3796 9.61385 9.61964 10.3739 8.67964 10.6072C8.15297 10.7405 7.64631 10.7139 7.20631 10.5672C7.20631 10.5672 6.25297 11.5205 5.56631 12.2072C5.23297 12.5405 5.33964 13.1272 5.78631 13.3005C6.49964 13.5739 7.23964 13.7139 7.99964 13.7139C9.18631 13.7139 10.3396 13.3672 11.393 12.7205C12.4663 12.0539 13.433 11.0739 14.213 9.82719C14.8463 8.82052 14.813 7.12719 14.1796 6.12052Z"
        fill="#8387A7"
      />
      <path
        d="M9.34708 6.65277L6.65375 9.34611C6.31375 8.99944 6.09375 8.51944 6.09375 7.99944C6.09375 6.95277 6.94708 6.09277 8.00042 6.09277C8.52042 6.09277 9.00042 6.31277 9.34708 6.65277Z"
        fill="#8387A7"
      />
      <path
        d="M12.1673 3.8328L9.90732 6.0928C9.42065 5.59947 8.74732 5.30613 8.00065 5.30613C6.50732 5.30613 5.30732 6.5128 5.30732 7.99947C5.30732 8.74613 5.60732 9.41947 6.09398 9.90613L3.84065 12.1661H3.83398C3.09398 11.5661 2.41398 10.7995 1.83398 9.8928C1.16732 8.84613 1.16732 7.14613 1.83398 6.09947C2.60732 4.88613 3.55398 3.9328 4.60732 3.27947C5.66065 2.63947 6.81398 2.28613 8.00065 2.28613C9.48732 2.28613 10.9273 2.8328 12.1673 3.8328Z"
        fill="#8387A7"
      />
      <path
        d="M9.90672 8.00005C9.90672 9.04672 9.05339 9.90672 8.00005 9.90672C7.96005 9.90672 7.92672 9.90672 7.88672 9.89339L9.89339 7.88672C9.90672 7.92672 9.90672 7.96005 9.90672 8.00005Z"
        fill="#8387A7"
      />
      <path
        d="M14.5146 1.48691C14.3146 1.28691 13.9879 1.28691 13.7879 1.48691L1.48789 13.7936C1.28789 13.9936 1.28789 14.3202 1.48789 14.5202C1.58789 14.6136 1.71456 14.6669 1.84789 14.6669C1.98122 14.6669 2.10789 14.6136 2.20789 14.5136L14.5146 2.20691C14.7212 2.00691 14.7212 1.68691 14.5146 1.48691Z"
        fill="#8387A7"
      />
    </svg>
  );
};
