interface WeyanWordImageProps {
  color?: string;
  width?: number;
  height?: number;
}

export const WeyanWordImage: React.FC<WeyanWordImageProps> = ({
  color = "white",
  width = 124,
  height = 32,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 125 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M30.2985 0L27.8242 9.59401C27.5845 10.7224 26.6427 11.5063 25.4859 11.5338C24.3242 11.5662 23.3453 10.8098 23.0536 9.67889L21.4148 3.0557C21.0638 1.68513 19.8378 0.726478 18.4338 0.726478C17.0323 0.726478 15.8063 1.68513 15.4528 3.0557L13.8115 9.67889C13.5223 10.8073 12.541 11.5538 11.3792 11.5338C10.2224 11.5063 9.28065 10.7199 9.03841 9.57653L6.56659 0.00249649L0.599609 0.0499298L6.02773 19.8646C6.31446 20.8657 6.90275 21.7245 7.72834 22.3511C8.5564 22.9777 9.53772 23.3097 10.5709 23.3097C12.7189 23.3097 14.6 21.8468 15.1463 19.7522L16.7085 13.7781C16.921 12.9618 17.5983 12.435 18.4338 12.435C19.2693 12.435 19.9466 12.9618 20.1591 13.7806L21.7189 19.7522C22.2651 21.8468 24.1462 23.3097 26.2942 23.3097C27.3299 23.3097 28.3112 22.9777 29.1368 22.3511C29.9624 21.7269 30.5507 20.8657 30.8399 19.8621L36.2655 0H30.2985Z"
        fill={color}
      />
      <path
        d="M77.6515 0C76.9297 2.56639 76.2351 4.93057 75.6246 7.02013C74.8756 9.57404 74.228 11.7784 73.793 13.4561C73.6792 13.8905 73.3208 14.1701 72.8784 14.1726C72.426 14.1676 72.0726 13.893 71.9589 13.4611C71.4645 11.6037 70.7378 9.28195 69.8949 6.59573C69.2695 4.60103 68.5601 2.3442 67.8655 0H61.5723L69.272 22.019C69.7317 23.3372 69.4425 24.7702 68.5156 25.7588C67.779 26.5452 66.8249 26.8822 65.3245 26.8822C64.84 26.8822 64.2369 26.8448 63.8266 26.8023V31.8427C64.8623 31.9576 66.0883 32 66.637 32C72.0998 32 73.8177 29.7332 75.9212 23.3447L83.4701 0H77.6515Z"
        fill={color}
      />
      <path
        d="M56.9547 12.8652C57.0239 12.2797 57.0239 11.7977 57.0239 11.0892C57.0239 5.56917 53.8006 0 46.6052 0C39.6865 0 35.7168 4.36636 35.7168 11.9822C35.7168 17.4577 38.5174 23.31 46.3728 23.31C51.7021 23.31 55.1453 20.9411 56.6086 16.2746L50.6911 16.2771C50.0459 17.5096 48.8249 18.3876 47.4159 18.6336C47.1243 18.663 46.8425 18.6777 46.5434 18.6777C46.1553 18.6777 45.7771 18.636 45.4187 18.5547C44.8749 18.4169 44.4052 18.2201 44.0196 17.9692C42.586 17.0296 41.7332 15.1699 41.7307 12.988V12.8649L56.9547 12.8652ZM41.9705 8.48158C42.4031 6.50635 44.163 5.06738 46.2492 4.98125C46.3011 4.97875 46.353 4.97625 46.4025 4.97875C46.4074 4.97625 46.4321 4.97625 46.4569 4.97625H46.4816C48.7863 4.97625 50.5576 6.47614 50.9432 8.48158L50.9754 8.63161H41.9384L41.9705 8.48158Z"
        fill={color}
      />
      <path
        d="M64.9583 19.7336C64.9583 21.5086 63.5296 22.9541 61.7721 22.9541C60.0147 22.9541 58.5859 21.5086 58.5859 19.7336C58.5859 17.9611 60.0147 16.5156 61.7721 16.5156C63.5296 16.5156 64.9583 17.9611 64.9583 19.7336Z"
        fill={color}
      />
      <path
        d="M124.6 8.59991V23.3097H118.583V9.29443C118.583 8.88076 118.558 8.47933 118.507 8.09761C118.198 6.20378 116.571 4.82946 114.643 4.82946C112.947 4.82946 111.454 5.9057 110.928 7.50395C110.928 7.50395 110.851 7.72314 110.849 7.73288C110.644 8.46435 110.54 9.35335 110.54 10.3779V23.3097H104.523V6.07795C104.523 4.24328 104.523 1.6704 104.484 0.0227181H110.534L110.557 1.14015C110.557 1.46269 110.725 1.74105 111.005 1.88135C111.316 2.03639 111.692 1.99944 111.988 1.7805C112.587 1.33962 114.401 0 117.226 0C121.843 0 124.6 3.21623 124.6 8.59991Z"
        fill={color}
      />
      <path
        d="M102.086 6.97319C101.923 4.67792 101.067 2.94511 99.53 1.77925C97.9925 0.615884 95.7728 0.0194726 92.8808 0.00249649C92.8412 0.00249649 92.8042 0 92.7671 0C87.1981 0 84.2665 2.33172 83.8092 7.12648H89.0594C89.4919 5.53222 90.9453 4.42977 92.6336 4.42977C94.1562 4.42977 95.5454 5.3692 96.0892 6.76873C96.198 7.04609 96.2524 7.34792 96.2524 7.66447V9.15163H92.9006C84.0984 9.15163 82.252 13.1166 82.252 16.4461C82.252 19.6102 84.2517 23.3097 89.8849 23.3097C91.9909 23.3097 93.5828 22.9178 94.752 22.1147C95.0609 21.9005 95.4465 21.8762 95.7802 22.049C96.0769 22.1998 96.2672 22.4724 96.3067 22.7937V23.3082H102.116V22.7937V22.4969V7.79579C102.116 7.50869 102.106 7.24082 102.086 6.97319ZM91.6647 19.1501C91.6226 19.1501 91.5831 19.1476 91.5435 19.1453L91.4817 19.1428C89.7737 19.0599 88.4365 17.6776 88.4365 15.9958C88.4365 14.584 89.4029 13.3355 90.7847 12.9583C91.5411 12.7538 92.4556 12.654 93.5828 12.654H95.5405C95.936 12.654 96.2573 12.9703 96.2573 13.3597C96.2573 16.3657 95.7011 19.1501 91.6647 19.1501Z"
        fill={color}
      />
    </svg>
  );
};
