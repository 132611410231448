import * as React from "react";
import TextField from "@mui/material/TextField";
import { WY_COLORS } from "../../constants/app-colors";
import { CSSProperties } from "styled-components";
import { CSSObject } from "@emotion/react";

interface InputAdornmentsProps {
  styles?: CSSProperties;
  placeholderStyles?: CSSProperties;
  name?: string;
  type?: "text" | "number" | "email" | "password";
  label?: string;
  multiline?: boolean;
  rows?: number;
  placeholder?: string;
  id?: string;
  fieldStyle?: CSSObject;
  fieldEndContent?: string | React.ReactNode;
  endAdornment?: string | React.ReactNode;
  startAdornment?: string | React.ReactNode;
  containerstyle?: CSSObject;
  disableSpinner?: boolean;
  errorMessage?: string;
  isRequired?: boolean;
  value?: string | number;
  onChange: (value: string) => void;
  isDisabled?: boolean;
  onBlur?: () => void;
  onFocus?: () => void;
  onClick?: () => void;
  component?: React.ElementType;
}

export default function InputAdornments(props: InputAdornmentsProps) {
  return (
    <TextField
      name={props.name}
      label={props.label}
      type={props.type}
      placeholder={props.placeholder}
      onBlur={props.onBlur}
      onFocus={props.onFocus}
      onClick={props.onClick}
      onChange={(e) => props?.onChange(e.target.value)}
      multiline={props.multiline}
      rows={props.rows}
      id={props.id}
      value={props.value}
      disabled={props.isDisabled}
      sx={{
        width: "100%",
        backgroundColor: WY_COLORS.primaryBlack2,
        border: `1px solid ${WY_COLORS.customDarkGray}`,
        display: "flex",
        justifyContent: "center",
        borderRadius: "50px",
        "& .MuiInputBase-input": {
          color: "white", // Set text color to white
          paddingRight: "4px",
          "&::placeholder": {
            color: "#9099A1",
            ...props.placeholderStyles,
          },
        },
        "& .MuiOutlinedInput-root": {
          paddingRight: "4px",

          "& fieldset": {
            border: `unset`,
          },
          "&:hover fieldset": {
            border: `none`,
          },
          "&.Mui-focused fieldset": {
            border: `none`,
          },
        },
        "&:focus": {
          outline: "unset !important",
          boxShadow: "unset !important",
        },
        ...props?.styles,
      }}
      InputProps={{
        startAdornment: props.startAdornment,
        endAdornment: props.endAdornment,
      }}
    />
  );
}
