import styled from "styled-components";

export const MainContent = styled.main({
  flex: 1,
});

export const StyledAppWrapperContainer = styled.div({
  background: "black",
  minHeight: "100vh",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  "@media (max-width: 1024px)": {
    width: "100%",
    overflow: "hidden",
  },
});
