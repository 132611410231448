import DecoratedText from "../../../components/DecoratedText/DecoratedText";
import { WY_COLORS } from "../../../constants/app-colors";
import { WY_IMAGES } from "../../../constants/images";
import {
  StyledDoubleGridDescriptionContainer,
  StyledGridSubTitle,
  StyledGridTitle,
} from "../../../constants/styles";
import { AppButton } from "../../../components/AppButton/AppButton";
import { InfoIcon } from "../../../assets/images/InfoIcon";
import { useNavigate } from "react-router-dom";
import { PAGES_ROUTES } from "../../../constants/pages-routes";
import {
  StyledAvatarDescriptions,
  StyledDesignedForAfricansContainer,
  StyledDesignedForAfricansWording,
  StyledFlagsAndTextContainer,
  StyledFlagsImages,
} from "../HomePage.styles";

import { stakeHolders } from "../HomePage.constants";
import { AppAvatar } from "../../../components/AppAvatar/AppAvatar";
import {
  StyledHeroImage,
  StyledMobileHomePageContainer,
  StyledMobileStakeholders,
  StyledNeedsHelpImage,
  StyledPageDescription,
  StyledSectionsImages,
  StyledWorldMapMobileImage,
} from "./MobileHomePage.styles";
import styled from "styled-components";
import ScrollAnimation from "../../../components/ScrollAnimation/ScrollAnimation";
import { EyeGlassesIcon } from "../../../assets/images/EyeGlassesIcon";

export const MobileHomePage = () => {
  const navigate = useNavigate();

  const scrollImages = [
    WY_IMAGES.PaymentSectionMobile,
    WY_IMAGES.ShopSectionMobile,
    WY_IMAGES.MarketplaceSectionMobile,
    WY_IMAGES.ChatSectionMobile,
  ];

  return (
    <div>
      <div style={{ position: "relative", minHeight: 750 }}>
        <StyledHeroImage src={WY_IMAGES.HeroWithBackgroundMobile} />
      </div>
      <ScrollAnimation>
        {scrollImages.map((element, index) => (
          <StyledSectionsImages src={element} key={index} />
        ))}
      </ScrollAnimation>
      <div
        style={{
          gap: 24,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginBottom: 70,
        }}
      >
        <StyledPageDescription style={{ fontSize: 40, textAlign: "center" }}>
          Join the <DecoratedText text="we.yan" /> revolution.
        </StyledPageDescription>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            textAlign: "center",
            alignItems: "center",
            gap: 16,
            fontSize: 16,
            color: WY_COLORS.whiteWithOpacity,
          }}
        >
          <div style={{ width: 210 }}>
            Step into a simpler, more powerful way of life.
          </div>
        </div>
      </div>
      <StyledMobileHomePageContainer>
        <StyledDoubleGridDescriptionContainer>
          <StyledGridTitle>Personal</StyledGridTitle>
          <StyledGridSubTitle>
            Dive into a world of free chat, exclusive emojis, dazzling stickers,
            and unbeatable transaction perks —custom-built  just for you.
          </StyledGridSubTitle>
          <AppButton
            buttonLevel="secondary"
            customStyles={{
              alignSelf: "flex-start",
              width: 116,
              height: 32,
              fontSize: 12,
              backgroundColor: "black",
              display: "flex",
              flexDirection: "row",
              gap: 8,
              alignItems: "center",
            }}
            onClick={() => {
              navigate(PAGES_ROUTES.personal);
            }}
          >
            <InfoIcon /> Learn more
          </AppButton>
        </StyledDoubleGridDescriptionContainer>
        <StyledDoubleGridDescriptionContainer>
          <StyledGridTitle>Business</StyledGridTitle>
          <StyledGridSubTitle>
            Make boss moves with we.yan business plans. Experience seamless
            storefront and order management, detailed analytics and personalized
            customer interactions.
          </StyledGridSubTitle>
          <AppButton
            buttonLevel="secondary"
            customStyles={{
              alignSelf: "flex-start",
              width: 116,
              height: 32,
              fontSize: 12,
              backgroundColor: "black",
              display: "flex",
              flexDirection: "row",
              gap: 8,
              alignItems: "center",
            }}
            onClick={() => {
              navigate(PAGES_ROUTES.business);
            }}
          >
            <InfoIcon /> Learn more
          </AppButton>
        </StyledDoubleGridDescriptionContainer>
      </StyledMobileHomePageContainer>
      <StyledDesignedForAfricansContainer>
        <StyledDesignedForAfricansWording style={{ fontSize: 24 }}>
          Designed for <DecoratedText text="Africans," />
        </StyledDesignedForAfricansWording>
        <StyledFlagsAndTextContainer>
          <StyledFlagsImages src={WY_IMAGES.flags} />
          <StyledDesignedForAfricansWording
            style={{ marginLeft: 80, fontSize: 24 }}
          >
            everywhere.
          </StyledDesignedForAfricansWording>
        </StyledFlagsAndTextContainer>
      </StyledDesignedForAfricansContainer>

      <div style={{ position: "relative" }}>
        <StyledMobileStakeholders>
          {stakeHolders.map((stakeholder, index) => (
            <StyledAvatarDescriptions style={{ marginBottom: 40 }} key={index}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <AppAvatar
                  styles={{ width: 90, height: 90 }}
                  key={index}
                  imageSrc={stakeholder.avatar}
                  name={stakeholder.name}
                />
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 4,
                  whiteSpace: "nowrap",
                }}
              >
                <div style={{ display: "flex", alignItems: "center", gap: 5 }}>
                  <div
                    style={{ display: "flex", flexDirection: "row", gap: 5 }}
                  >
                    <AppAvatar
                      styles={{ height: 18, width: 18 }}
                      key={index}
                      imageSrc={stakeholder.flag}
                    />
                    {stakeholder.flag2 && (
                      <AppAvatar
                        styles={{ height: 18, width: 18 }}
                        key={index}
                        imageSrc={stakeholder.flag2}
                      />
                    )}
                  </div>

                  {stakeholder.name}
                </div>
                <span
                  style={{
                    color: WY_COLORS.whiteWithOpacityTwo,
                  }}
                >
                  {stakeholder.position}
                </span>
              </div>
            </StyledAvatarDescriptions>
          ))}
        </StyledMobileStakeholders>
        <StyledWorldMapMobileImage src={WY_IMAGES.worldMapBlueDots} />
      </div>
      <div style={{ position: "relative" }}>
        <StyledNeedsHelpImage src={WY_IMAGES.needsHelpMobile} />
        <AppButton
          buttonLevel="secondary"
          customStyles={{
            alignSelf: "flex-start",
            width: 155,
            height: 36,
            fontSize: 12,
            backgroundColor: "black",
            display: "flex",
            flexDirection: "row",
            gap: 8,
            alignItems: "center",
            position: "absolute",
            top: "51%",
            left: "8%",
            zIndex: 2,
          }}
          onClick={() => {
            navigate(PAGES_ROUTES.faq);
          }}
        >
          <EyeGlassesIcon /> View Help Center
        </AppButton>
      </div>
    </div>
  );
};
