export const PAGES_ROUTES: any = {
  home: "/home",
  business: "/business",
  aboutUs: "/about-us",
  personal: "/personal",
  faq: "/faq",
  features: {
    payments: "/features/payments",
    wallets: "/features/wallets",
    chats: "/features/chats",
    marketPlace: "/features/marketPlace",
  },
};
