import { useEffect, useState } from "react";
import styled from "styled-components";
import { WY_IMAGES } from "../../../constants/images";
import { WEB_OPTIONS } from "../Header.constants";
import { WY_COLORS } from "../../../constants/app-colors";
import { AppButton } from "../../AppButton/AppButton";
import { useNavigate, useLocation } from "react-router-dom";
import { PAGES_ROUTES } from "../../../constants/pages-routes";
import { WeyanWordImage } from "../../../assets/images/WeyanWordImage";

export const StyledWebHeaderContainer = styled.header({
  display: "flex",
  alignItems: "center",
  margin: "40px 140px",
  height: 59,
  justifyContent: "center",
  flexDirection: "row",
  gap: 200,
  whiteSpace: "nowrap",
  zIndex: 9999999,
  border: "1px solid black",
  background: "black",
  borderRadius: 900,

  /* Media query for tablets */
  "@media (min-width: 768px) and (max-width: 1024px)": {
    width: "max-content",
    alignSelf: "center",
    padding: 25,
  },
});

export const StyledWebOptions = styled.header({
  display: "flex",
  flexDirection: "row",
  gap: 40,
  color: WY_COLORS.whiteWithOpacityTwo,
});

export const StyledOption = styled.div<{
  $isSelected: boolean;
  $isClickable: boolean;
}>(({ $isSelected: isSelected, $isClickable: isClickable }) => ({
  color: isSelected
    ? WY_COLORS.primaryBrightBlue300
    : WY_COLORS.whiteWithOpacityTwo,
  cursor: isClickable ? "pointer" : "default",
  "&:hover": {
    color: isClickable
      ? WY_COLORS.primaryBrightBlue300
      : WY_COLORS.whiteWithOpacityTwo,
  },
}));

export const StyledWeyanBlackBackgroundImage = styled.img({
  width: 80,
  cursor: "pointer",
});

export const WebHeader = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedOption, setSelectedOption] = useState<string>("");

  useEffect(() => {
    const currentPath = location.pathname;
    const matchingOption = WEB_OPTIONS.find((option) =>
      currentPath.includes(PAGES_ROUTES[option.value])
    );
    if (matchingOption) {
      setSelectedOption(matchingOption.value);
    } else {
      setSelectedOption("");
    }
  }, [location.pathname]);

  const handleOptionClick = (value: string) => {
    if (value !== "features") {
      setSelectedOption(value);
      navigate(PAGES_ROUTES[value]);
    }
  };

  return (
    <StyledWebHeaderContainer>
      <div
        style={{ cursor: "pointer", display: "flex" }}
        onClick={() => navigate(PAGES_ROUTES.home)}
      >
        <WeyanWordImage width={62} height={16} />
      </div>
      {/* <StyledWeyanBlackBackgroundImage
        src={WY_IMAGES.weyanLogoBlackBackground}
        onClick={() => navigate(PAGES_ROUTES.home)}
      /> */}
      <StyledWebOptions>
        {WEB_OPTIONS.map((option) => (
          <StyledOption
            key={option.value}
            $isSelected={option.value === selectedOption}
            $isClickable={option.value !== "features"}
            onClick={() => handleOptionClick(option.value)}
          >
            {option.title}
          </StyledOption>
        ))}
      </StyledWebOptions>
      {/* <AppButton
        customStyles={{
          height: 43,
          width: 155,
          fontSize: 16,
          fontWeight: 600,
        }}
        buttonLevel="primary"
      >
        Get invited!
      </AppButton> */}
    </StyledWebHeaderContainer>
  );
};
