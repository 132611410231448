import TitleSubtitleDescriptionComponent from "../../components/shared/TitleSubtitleDescriptionComponent/TitleSubtitleDescriptionComponent";
import { useScreenWidth } from "../../hooks/useScreenWidth";
import { WY_IMAGES } from "../../constants/images";
import { StyledLastSectionContainer } from "../AboutUsPage/AboutUsPage.styles";
import DecoratedText from "../../components/DecoratedText/DecoratedText";
import { AppButton } from "../../components/AppButton/AppButton";
import { PersonalCardIcon } from "../../assets/images/PersonalCardIcon";
import { InfoIcon } from "../../assets/images/InfoIcon";
import {
  StyledDoubleGridContainer,
  StyledDoubleGridDescriptionContainer,
  StyledGridContainer,
  StyledGridImage,
  StyledGridSubTitle,
  StyledGridTitle,
  StyledColoredText,
  StyledPageContainer,
  StyledSectionContainer,
} from "../../constants/styles";

export default function PersonalPage() {
  const screenWidth = useScreenWidth();

  return (
    <StyledPageContainer>
      <StyledSectionContainer>
        <TitleSubtitleDescriptionComponent
          IconComponent={PersonalCardIcon}
          titleSpan="Elevate your"
          titleSpanTwo="digital experience."
          smallTitle="we.yan Personal Membership"
          subTitle="Explore “you-centered” membership options designed to elevate your lifestyle to new levels."
          containerStyle={{
            paddingTop: screenWidth && screenWidth <= 1025 ? "0%" : 104,
          }}
        />
      </StyledSectionContainer>
      {/* <ResponsiveDiv /> */}
      <StyledGridContainer style={{ backgroundImage: "none" }}>
        <StyledDoubleGridContainer>
          <StyledDoubleGridDescriptionContainer
            style={{
              borderRight:
                screenWidth && screenWidth >= 1025 ? "0.5px solid #1A1A1A" : 0,
            }}
          >
            <StyledGridTitle>Wallets</StyledGridTitle>
            <StyledGridSubTitle>
              Set up in seconds, seamless top-ups and transfers, and more secure
              and creative ways to manage your money.
            </StyledGridSubTitle>
            {/* <AppButton
              buttonLevel="secondary"
              customStyles={{
                alignSelf: "flex-start",
                width: 116,
                height: 32,
                fontSize: 12,
                backgroundColor: "black",
                display: "flex",
                flexDirection: "row",
                gap: 8,
                alignItems: "center",
              }}
            >
              <InfoIcon /> Learn more
            </AppButton> */}
            <StyledGridImage src={WY_IMAGES.WalletWithTransaction} />
          </StyledDoubleGridDescriptionContainer>
          <StyledDoubleGridDescriptionContainer>
            <StyledGridTitle>Shop</StyledGridTitle>
            <StyledGridSubTitle>
              Get unlimited access to premium products and services, from
              verified merchants you can trust.
            </StyledGridSubTitle>
            {/* <AppButton
              buttonLevel="secondary"
              customStyles={{
                alignSelf: "flex-start",
                display: "flex",
                flexDirection: "row",
                gap: 8,
                width: 116,
                height: 32,
                fontSize: 12,
                backgroundColor: "black",
                alignItems: "center",
              }}
            >
              <InfoIcon /> Learn more
            </AppButton> */}
            <StyledGridImage src={WY_IMAGES.PersonalPageMobile2} />
          </StyledDoubleGridDescriptionContainer>
        </StyledDoubleGridContainer>
        <StyledDoubleGridContainer style={{ width: "100%" }}>
          <StyledDoubleGridDescriptionContainer
            style={{
              borderRight:
                screenWidth && screenWidth >= 1025 ? "1px solid #1A1A1A" : 0,
            }}
          >
            <StyledGridTitle>Chat</StyledGridTitle>
            <StyledGridSubTitle>
              Stay in touch with the people that matter to you. Explore new,
              innovative ways to express how you feel, in every situation.
            </StyledGridSubTitle>
            {/* <AppButton
              buttonLevel="secondary"
              customStyles={{
                alignSelf: "flex-start",
                width: 116,
                height: 32,
                fontSize: 12,
                backgroundColor: "black",
                display: "flex",
                flexDirection: "row",
                gap: 8,
                alignItems: "center",
              }}
            >
              <InfoIcon /> Learn more
            </AppButton> */}
          </StyledDoubleGridDescriptionContainer>
          <StyledDoubleGridDescriptionContainer>
            <StyledGridTitle>Pay</StyledGridTitle>
            <StyledGridSubTitle>
              Lightning-fast, drama free end-to-end encrypted transactions
              without borders.
            </StyledGridSubTitle>
            {/* <AppButton
              buttonLevel="secondary"
              customStyles={{
                alignSelf: "flex-start",
                width: 116,
                height: 32,
                fontSize: 12,
                backgroundColor: "black",
                display: "flex",
                flexDirection: "row",
                gap: 8,
                alignItems: "center",
              }}
            >
              <InfoIcon /> Learn more
            </AppButton> */}
          </StyledDoubleGridDescriptionContainer>
        </StyledDoubleGridContainer>
        <StyledDoubleGridDescriptionContainer
          style={{
            flexDirection:
              screenWidth && screenWidth >= 1025 ? "row" : "column",
            justifyContent: "space-between",
          }}
        >
          <StyledGridTitle>Privacy and Security</StyledGridTitle>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 16,
              alignItems: "flex-start",
              maxWidth: screenWidth && screenWidth >= 1025 ? 500 : "100%",
            }}
          >
            <StyledGridSubTitle>
              we.yan has a zero-tolerance policy for scammers and phishers. All
              your conversations are device encrypted and your financial
              activities are end-to-end encrypted.
            </StyledGridSubTitle>
            {/* <AppButton
              buttonLevel="secondary"
              customStyles={{
                alignSelf: "flex-start",
                width: 116,
                height: 32,
                fontSize: 12,
                backgroundColor: "black",
                display: "flex",
                flexDirection: "row",
                gap: 8,
                alignItems: "center",
              }}
            >
              <InfoIcon /> Learn more
            </AppButton> */}
          </div>
        </StyledDoubleGridDescriptionContainer>
      </StyledGridContainer>
      <StyledLastSectionContainer style={{ maxWidth: 600 }}>
        <StyledColoredText>
          Unleash <DecoratedText text="your potential" /> today!
        </StyledColoredText>
        {/* <AppButton customStyles={{ width: 155, fontSize: 16 }}>
          Get invited!
        </AppButton> */}
      </StyledLastSectionContainer>
    </StyledPageContainer>
  );
}
