interface PaymentIconProps {
  color?: string;
  width?: number;
  height?: number;
}

export const PaymentIcon: React.FC<PaymentIconProps> = ({
  color = "#FEA1D4",
  width = 16,
  height = 16,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.66634 1.33301H3.99967C1.99967 1.33301 1.33301 2.52634 1.33301 3.99967V4.66634V13.9997C1.33301 14.553 1.95967 14.8663 2.39967 14.533L3.53967 13.6797C3.80634 13.4797 4.17967 13.5063 4.41967 13.7463L5.52634 14.8597C5.78634 15.1197 6.21301 15.1197 6.47301 14.8597L7.59301 13.7397C7.82634 13.5063 8.19967 13.4797 8.45967 13.6797L9.59967 14.533C10.0397 14.8597 10.6663 14.5463 10.6663 13.9997V2.66634C10.6663 1.93301 11.2663 1.33301 11.9997 1.33301H4.66634ZM3.97967 9.33967C3.61301 9.33967 3.31301 9.03967 3.31301 8.67301C3.31301 8.30634 3.61301 8.00634 3.97967 8.00634C4.34634 8.00634 4.64634 8.30634 4.64634 8.67301C4.64634 9.03967 4.34634 9.33967 3.97967 9.33967ZM3.97967 6.67301C3.61301 6.67301 3.31301 6.37301 3.31301 6.00634C3.31301 5.63967 3.61301 5.33967 3.97967 5.33967C4.34634 5.33967 4.64634 5.63967 4.64634 6.00634C4.64634 6.37301 4.34634 6.67301 3.97967 6.67301ZM7.99967 9.17301H5.99967C5.72634 9.17301 5.49967 8.94634 5.49967 8.67301C5.49967 8.39967 5.72634 8.17301 5.99967 8.17301H7.99967C8.27301 8.17301 8.49967 8.39967 8.49967 8.67301C8.49967 8.94634 8.27301 9.17301 7.99967 9.17301ZM7.99967 6.50634H5.99967C5.72634 6.50634 5.49967 6.27967 5.49967 6.00634C5.49967 5.73301 5.72634 5.50634 5.99967 5.50634H7.99967C8.27301 5.50634 8.49967 5.73301 8.49967 6.00634C8.49967 6.27967 8.27301 6.50634 7.99967 6.50634Z"
        fill={color}
      />
      <path
        d="M12.007 1.33301V2.33301C12.447 2.33301 12.867 2.51301 13.1737 2.81301C13.4937 3.13967 13.667 3.55967 13.667 3.99967V5.61301C13.667 6.10634 13.447 6.33301 12.947 6.33301H11.667V2.67301C11.667 2.48634 11.8203 2.33301 12.007 2.33301V1.33301ZM12.007 1.33301C11.267 1.33301 10.667 1.93301 10.667 2.67301V7.33301H12.947C14.0003 7.33301 14.667 6.66634 14.667 5.61301V3.99967C14.667 3.26634 14.367 2.59967 13.887 2.11301C13.4003 1.63301 12.7403 1.33967 12.007 1.33301C12.007 1.33301 12.0137 1.33301 12.007 1.33301Z"
        fill={color}
      />
    </svg>
  );
};
