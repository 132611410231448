import styled from "styled-components";

export const StyledSectionImage = styled.img({
  width: 1460,
  height: 900,

  /* Media query for tablets */
  "@media (min-width: 768px) and (max-width: 1024px)": {
    width: 900,
    height: 600,
  },
});
