import styled from "styled-components";
import { WY_COLORS } from "./app-colors";
import { WY_IMAGES } from "./images";

export const StyledPageContainer = styled.div({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  gap: 100,
  padding: 20,
  " @media only screen and (min-width: 1025px)": {
    gap: 150,
    // padding: 0,
    alignItems: "center",
  },
});
export const StyledSectionContainer = styled.div({
  display: "flex",
  justifyContent: "center",
  alignItems: "flex-start",
  maxWidth: 622,
  "@media only screen and (min-width: 1025px)": {
    alignItems: "center",
  },
});

export const StyledColoredText = styled.div({
  fontFamily: "GT Walsheim",
  fontSize: 24,
  fontWeight: 500,
  lineHeight: "27px",
  maxWidth: 235,
  textAlign: "center",

  "@media only screen and (min-width: 600px)": {
    fontSize: 64,
    maxWidth: 650,
    lineHeight: "72px",
  },
});

export const StyledHeadingContainer = styled.div({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  gap: 40,
  alignSelf: "flex-start",

  "@media only screen and (min-width: 1025px)": {
    flexDirection: "row",
    gap: "4%",
    justifyContent: "space-evenly",
    alignSelf: "center",
  },
  "@media (min-width: 768px) and (max-width: 1024px)": {
    flexDirection: "row",
    gap: "4%",
    justifyContent: "space-evenly",
    alignSelf: "center",
  },
});

export const StyledGridContainer = styled.div({
  width: "100%",
  "@media only screen and (min-width: 1025px)": {
    padding: "0 80px",
    backgroundImage: `url(${WY_IMAGES.coloredBg})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  /* Media query for tablets */
  "@media (min-width: 768px) and (max-width: 1024px)": {
    padding: "0 80px",
    backgroundImage: `url(${WY_IMAGES.coloredBg})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
});
export const StyledSingleGridContainer = styled.div({
  border: "0.5px solid #1A1A1A",
  borderWidth: "0 0",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",
  gap: 40,
  padding: "40px 0",
  "@media only screen and (min-width: 1025px)": {
    flexDirection: "row",
    borderWidth: "0.5px 0",
    padding: 40,
    alignItems: "flex-start",
  },
});
export const StyledDoubleGridContainer = styled.div({
  border: "0.5px solid #1A1A1A",
  borderWidth: "0 0",
  display: "grid",
  gridTemplateColumns: "repeat(1,1fr)",
  alignItems: "flex-start",
  justifyContent: "center",
  width: "100%",
  "@media only screen and (min-width: 1025px)": {
    gridTemplateColumns: "repeat(2,1fr)",
    borderWidth: "0.5px 0",
    alignItems: "flex-start",
  },
});
export const StyledGridDescriptionContainer = styled.div({
  width: "100%",
  display: "flex",
  gap: 16,
  flexDirection: "column",
  padding: 15,
  "@media only screen and (min-width: 1025px)": {
    width: "40%",
    padding: "76px 90px",
  },
});
export const StyledDoubleGridDescriptionContainer = styled.div({
  width: "100%",
  display: "flex",
  gap: 16,
  border: "0.5px solid #1A1A1A",
  borderWidth: "1px 0",
  height: "100%",
  padding: "40px 0",
  flexDirection: "column",
  "@media only screen and (min-width: 1025px)": {
    gridTemplateColumns: "repeat(2,1fr)",
    borderWidth: "0",
    padding: "76px 90px",
  },
});
export const StyledGridTitle = styled.div({
  fontSize: 24,
  fontWeight: 600,
  lineHeight: "32px",
  color: WY_COLORS.nuetralGrey100,
});
export const StyledGridSubTitle = styled.div({
  fontSize: 16,
  fontWeight: 400,
  lineHeight: "22px",
  color: WY_COLORS.whiteWithOpacity,
});

export const StyledGridImage = styled.img({
  width: 343,
  height: 380,
  objectFit: "cover",
  borderRadius: 16,
  alignSelf: "center",
  "@media only screen and (min-width: 1200px)": {
    width: 472,
    height: 378,
  },
});

export const StyledColoredTextDescription = styled.div({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  gap: 40,
  alignSelf: "flex-start",
  "@media only screen and (min-width: 1025px)": {
    flexDirection: "row",
    gap: 200,
    justifyContent: "space-evenly",
    alignSelf: "center",
    alignItems: "flex-start",
  },
  "@media (min-width: 768px) and (max-width: 1024px)": {
    flexDirection: "row",
    gap: 50,
    justifyContent: "space-evenly",
    alignSelf: "center",
    alignItems: "flex-start",
  },
});
export const StyledTitle = styled.div({
  fontFamily: "GT Walsheim",
  fontSize: 24,
  fontWeight: 500,
  lineHeight: "27px",
  textAlign: "left",
  "@media only screen and (min-width: 600px)": {
    fontSize: 56,
    lineHeight: "60px",
    textAlign: "left",
  },
});
export const StyledDescription = styled.div({
  textAlign: "left",
  fontSize: 16,
  fontWeight: 500,
  lineHeight: "22px",
  color: WY_COLORS.whiteWithOpacity,
  display: "flex",
  alignItems: "flex-start",
  flexDirection: "column",
  gap: 16,
  "@media only screen and (min-width: 600px)": {
    maxWidth: 450,
    width: "100%",
  },
});
