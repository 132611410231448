interface PersonalCardIconProps {
  color?: string;
  width?: number;
  height?: number;
}

export const PersonalCardIcon: React.FC<PersonalCardIconProps> = ({
  color = "white",
  width = 17,
  height = 16,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.8334 2.73956L9.50677 1.49289C8.95344 1.28622 8.04677 1.28622 7.49344 1.49289L4.16677 2.73956C3.4001 3.02622 2.77344 3.93289 2.77344 4.74622V9.69956C2.77344 10.4862 3.29344 11.5196 3.92677 11.9929L6.79344 14.1329C7.73344 14.8396 9.2801 14.8396 10.2201 14.1329L13.0868 11.9929C13.7201 11.5196 14.2401 10.4862 14.2401 9.69956V4.74622C14.2268 3.93289 13.6001 3.02622 12.8334 2.73956ZM8.45344 4.68622C9.2401 4.68622 9.8801 5.32622 9.8801 6.11289C9.8801 6.88622 9.27344 7.50622 8.50677 7.53289H8.49344H8.4801C8.46677 7.53289 8.45344 7.53289 8.4401 7.53289C7.6401 7.50622 7.0401 6.88622 7.0401 6.11289C7.03344 5.32622 7.67344 4.68622 8.45344 4.68622ZM9.9601 10.9062C9.55344 11.1729 9.02677 11.3129 8.5001 11.3129C7.97344 11.3129 7.4401 11.1796 7.0401 10.9062C6.6601 10.6529 6.45344 10.3062 6.44677 9.92622C6.44677 9.55289 6.6601 9.19289 7.0401 8.93956C7.84677 8.40622 9.1601 8.40622 9.96677 8.93956C10.3468 9.19289 10.5601 9.53956 10.5601 9.91956C10.5534 10.2929 10.3401 10.6529 9.9601 10.9062Z"
        fill={color}
      />
    </svg>
  );
};
