import styled from "styled-components";
import { WY_COLORS } from "../../../constants/app-colors";

export const StyledContainer = styled.div({
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
});
export const StyledSmallTitle = styled.div({
  display: "flex",
  alignItems: "center",
  gap: 8,
  justifyContent: "flex-start",
  fontSize: 14,
  fontWeight: 600,
  lineHeight: "22px",
  color: WY_COLORS.nuetralGrey700,
});
export const StyledTitle = styled.div`
  margin-top: 12px;
  margin-bottom: 28px;
  font-size: 24px;
  white-space: nowrap;
  font-family: GT Walsheim;
  justify-content: center;
  display: flex;
  flex-direction: column;
  font-weight: 500;
  line-height: 27px;
  color: ${WY_COLORS.nuetralGrey100};
  @media only screen and (min-width: 600px) {
    font-size: 64px;
    line-height: 75px;
  }
`;
export const StyledSubTitle = styled.div({
  fontSize: 16,
  fontWeight: 500,
  lineHeight: "22px",
  color: "#FFFFFFCC",
});
