import styled from "styled-components";
import { WY_COLORS } from "../../../constants/app-colors";

export const StyledDescriptionTitle = styled.div({
  fontSize: 20,
  fontWeight: 600,
  lineHeight: "24px",
  color: WY_COLORS.nuetralGrey100,
});
export const StyledDescriptionSubTitle = styled.div({
  fontSize: 14,
  fontWeight: 400,
  lineHeight: "22px",
  color: WY_COLORS.nuetralGrey700,
});
export const StyledWalletDescriptionContainer = styled.div({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "space-between",
  paddingBottom: 40,
  gap: 40,
  borderBottom: "1px solid rgba(255, 255, 255, 0.2)",
  "@media only screen and (min-width: 600px)": {
    flexDirection: "row",
  },
});
export const StyledWalletDescription = styled.div({
  display: "flex",
  flexDirection: "column",
  gap: 16,
  alignItems: "flex-start",
  "@media only screen and (min-width: 600px)": {
    width: 360,
  },
});
export const StyledWalletImgDescription = styled.div({
  display: "flex",
  flexDirection: "column",
  gap: 80,
});

export const StyledBannerImage = styled.img({
  width: 343,
  height: 216,
  objectFit: "cover",
  borderRadius: 16,
  alignSelf: "center",
  "@media only screen and (min-width: 1200px)": {
    width: 1104,
    height: 615,
  },
  "@media (min-width: 768px) and (max-width: 1024px)": {
    height: 415,
    width: 700,
  },
});
export const StyledWalletImage = styled.img({
  width: 343,
  height: 216,
  objectFit: "cover",
  borderRadius: 16,
  alignSelf: "center",
  "@media only screen and (min-width: 1200px)": {
    width: 665,
    height: 400,
    marginTop: 60,
  },
});
