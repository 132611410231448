import { LandingPageImage } from "../../../assets/images/LandingPageImage";
import { WY_IMAGES } from "../../../constants/images";
import { AfricaPotentialUnleashedWording } from "../../../assets/images/AfricaPotentialUnleashedWording";
import { WY_COLORS } from "../../../constants/app-colors";
import { AppButton } from "../../../components/AppButton/AppButton";
import ScrollAnimation from "../../../components/ScrollAnimation/ScrollAnimation";
import { PaymentSection } from "../Sections/PaymentSection/PaymentSection";
import { ShopSection } from "../Sections/ShopSection/ShopSection";
import { MarketPlaceSection } from "../Sections/MarketPlaceSection/MarketPlaceSection";
import { ChatSection } from "../Sections/ChatSection/ChatSection";
import {
  StyleHomePageContainer,
  StyledBluredImage,
  StyledCenteredText,
  StyledAfrica,
  StyledBuiltForDreamersContainer,
  StyledLandingPageImageContainer,
  StyledWhiteShadesImage,
  StyledPhoneAndCallouts,
  StyledOneAppContainer,
  StyledOneAppWording,
  StyledGreenShadesImage,
  StyledWeyanRevolutionSection,
  StyledJoinWeyanWording,
  StyledJoinWeyanDescription,
  StyledStakeholders,
  StyledWorldMapBlueDotsImage,
  StyledNeedHelpImage,
  StyledHeroWithBackgroungImage,
} from "./WebHomePage.styles";
import {
  StyledDoubleGridContainer,
  StyledDoubleGridDescriptionContainer,
  StyledGridContainer,
  StyledGridSubTitle,
  StyledGridTitle,
} from "../../../constants/styles";
import { InfoIcon } from "../../../assets/images/InfoIcon";
import DecoratedText from "../../../components/DecoratedText/DecoratedText";
import { AppAvatar } from "../../../components/AppAvatar/AppAvatar";
import {
  StyledAvatarDescriptions,
  StyledDesignedForAfricansContainer,
  StyledDesignedForAfricansWording,
  StyledFlagsAndTextContainer,
  StyledFlagsImages,
} from "../HomePage.styles";
import { stakeHolders } from "../HomePage.constants";
import { useNavigate } from "react-router-dom";
import { PAGES_ROUTES } from "../../../constants/pages-routes";
import { EyeGlassesIcon } from "../../../assets/images/EyeGlassesIcon";

export const WebHomePage = () => {
  const navigate = useNavigate();

  const scrollComponents = [
    <PaymentSection />,
    <ShopSection />,
    <MarketPlaceSection />,
    <ChatSection />,
  ];

  return (
    <div>
      <div style={{ height: 1000 }}>
        <StyledHeroWithBackgroungImage src={WY_IMAGES.HeroWithBackground} />
      </div>
      <StyledPhoneAndCallouts src={WY_IMAGES.phoneAndCallouts} />
      <StyledOneAppContainer>
        <StyledOneAppWording>
          <div>One app</div>
          <div>Endless Possibilities</div>
        </StyledOneAppWording>
        <StyledGreenShadesImage src={WY_IMAGES.greenShades} />
      </StyledOneAppContainer>
      <div style={{ margin: "400px 0px 150px 0px" }}>
        <ScrollAnimation>
          {scrollComponents.map((element, index) => (
            <div key={index}>{element}</div>
          ))}
        </ScrollAnimation>
      </div>
      <StyledWeyanRevolutionSection>
        <div>
          <StyledJoinWeyanWording>Join the we.yan</StyledJoinWeyanWording>
          <div style={{ display: "flex", alignItems: "center", gap: 50 }}>
            <StyledJoinWeyanWording>revolution.</StyledJoinWeyanWording>
            <StyledJoinWeyanDescription>
              Step into a simpler, more powerful way of life. Become a we.yan
              member for free, and access limitless possibilities.
            </StyledJoinWeyanDescription>
          </div>
        </div>
      </StyledWeyanRevolutionSection>
      <StyledGridContainer style={{ backgroundImage: "unset" }}>
        <StyledDoubleGridContainer>
          <StyledDoubleGridDescriptionContainer
            style={{
              borderRight: `1px solid ${WY_COLORS.customVeryDarkGray}`,
            }}
          >
            <StyledGridTitle>Personal</StyledGridTitle>
            <StyledGridSubTitle>
              Dive into a world of free chat, exclusive emojis, dazzling
              stickers, and unbeatable transaction perks that are custom-built 
              just for you.
            </StyledGridSubTitle>
            <AppButton
              buttonLevel="secondary"
              customStyles={{
                alignSelf: "flex-start",
                width: 116,
                height: 32,
                fontSize: 12,
                backgroundColor: "black",
                display: "flex",
                flexDirection: "row",
                gap: 8,
                alignItems: "center",
              }}
              onClick={() => {
                navigate(PAGES_ROUTES.personal);
              }}
            >
              <InfoIcon /> Learn more
            </AppButton>
          </StyledDoubleGridDescriptionContainer>
          <StyledDoubleGridDescriptionContainer>
            <StyledGridTitle>Business</StyledGridTitle>
            <StyledGridSubTitle>
              Make boss moves with we.yan business plans. Experience seamless
              storefront and order management, detailed analytics and
              personalized customer interactions.
            </StyledGridSubTitle>
            <AppButton
              buttonLevel="secondary"
              customStyles={{
                alignSelf: "flex-start",
                display: "flex",
                flexDirection: "row",
                gap: 8,
                width: 116,
                height: 32,
                fontSize: 12,
                backgroundColor: "black",
                alignItems: "center",
              }}
              onClick={() => {
                navigate(PAGES_ROUTES.business);
              }}
            >
              <InfoIcon /> Learn more
            </AppButton>
          </StyledDoubleGridDescriptionContainer>
        </StyledDoubleGridContainer>
      </StyledGridContainer>

      <StyledDesignedForAfricansContainer>
        <StyledDesignedForAfricansWording>
          Designed for <DecoratedText text="Africans," />
        </StyledDesignedForAfricansWording>
        <StyledFlagsAndTextContainer>
          <StyledFlagsImages src={WY_IMAGES.flags} />
          <StyledDesignedForAfricansWording style={{ marginLeft: 100 }}>
            everywhere.
          </StyledDesignedForAfricansWording>
        </StyledFlagsAndTextContainer>
      </StyledDesignedForAfricansContainer>
      <div style={{ position: "relative", marginBottom: 150 }}>
        <StyledStakeholders>
          {stakeHolders.map((stakeholder, index) => (
            <StyledAvatarDescriptions key={index}>
              <AppAvatar
                styles={{ width: 70, height: 70 }}
                key={index}
                imageSrc={stakeholder.avatar}
                name={stakeholder.name}
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 4,
                  whiteSpace: "nowrap",
                }}
              >
                <div style={{ display: "flex", alignItems: "center", gap: 5 }}>
                  <div
                    style={{ display: "flex", flexDirection: "row", gap: 7 }}
                  >
                    <AppAvatar
                      styles={{ height: 25, width: 25 }}
                      key={index}
                      imageSrc={stakeholder.flag}
                    />
                    {stakeholder.flag2 && (
                      <AppAvatar
                        styles={{ height: 25, width: 25 }}
                        key={index}
                        imageSrc={stakeholder.flag2}
                      />
                    )}
                  </div>

                  {stakeholder.name}
                </div>
                <span
                  style={{
                    color: WY_COLORS.whiteWithOpacityTwo,
                  }}
                >
                  {stakeholder.position}
                </span>
              </div>
            </StyledAvatarDescriptions>
          ))}
        </StyledStakeholders>
        <StyledWorldMapBlueDotsImage src={WY_IMAGES.worldMapBlueDots} />
      </div>
      <div style={{ position: "relative" }}>
        <StyledNeedHelpImage src={WY_IMAGES.needHelp} />
        <AppButton
          buttonLevel="secondary"
          customStyles={{
            alignSelf: "flex-start",
            width: 155,
            height: 36,
            fontSize: 12,
            backgroundColor: "black",
            display: "flex",
            flexDirection: "row",
            gap: 8,
            alignItems: "center",
            position: "absolute",
            top: "78%",
            left: "30%",
            zIndex: 2,
          }}
          onClick={() => {
            navigate(PAGES_ROUTES.faq);
          }}
        >
          <EyeGlassesIcon /> View Help Center
        </AppButton>
      </div>
    </div>
  );
};
