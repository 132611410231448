import { useScreenWidth } from "../../hooks/useScreenWidth";
import { MobileHeader } from "./MobileHeader/MobileHeader";
import { WebHeader } from "./WebHeader/WebHeader";

export const Header = () => {
  const screenWidth = useScreenWidth();

  if (screenWidth === 0) {
    return <div></div>;
  }
  return <>{screenWidth <= 600 ? <MobileHeader /> : <WebHeader />}</>;
};
