import * as React from "react";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";

interface AppBurgerMenuProps {
  children: React.ReactNode;
  isOpen: boolean;
  setMenuOpen: (isOpen: boolean) => void;
}

export default function AppBurgerMenu({
  children,
  isOpen,
  setMenuOpen,
}: AppBurgerMenuProps) {
  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }
      setMenuOpen(open);
    };

  const list = () => (
    <Box sx={{ width: "100%" }}>
      <IconButton onClick={toggleDrawer(false)} color="inherit">
        <CloseIcon />
      </IconButton>
      <div
        style={{
          color: "white",
          padding: "0px 32px 42px 16px",
          height: "110vh",
        }}
      >
        {children}
      </div>
    </Box>
  );

  return (
    <div>
      <IconButton onClick={toggleDrawer(true)} color="inherit">
        {isOpen ? <CloseIcon /> : <MenuIcon />}
      </IconButton>
      <SwipeableDrawer
        anchor={"right"}
        open={isOpen}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        PaperProps={{
          sx: {
            width: "100%",
            position: "absolute",
            top: 90,
            background: "black",
          },
        }}
        ModalProps={{
          disablePortal: true,
          BackdropProps: {
            sx: {
              backgroundColor: "transparent",
            },
          },
        }}
      >
        {list()}
      </SwipeableDrawer>
    </div>
  );
}
