import { LandingPageImage } from "../../assets/images/LandingPageImage";
import { WY_IMAGES } from "../../constants/images";
import { useScreenWidth } from "../../hooks/useScreenWidth";
import { MobileHomePage } from "./MobileHomePage/MobileHomePage";
import { WebHomePage } from "./WebHomePage/WebHomePage";

export const HomePage = () => {
  const screenWidth = useScreenWidth();

  if (screenWidth === 0) {
    return <></>;
  }

  return <div>{screenWidth <= 600 ? <MobileHomePage /> : <WebHomePage />}</div>;
};
