import styled from "styled-components";
import { WY_COLORS } from "../../constants/app-colors";

export const StyledFAQTitle = styled.div({
  fontSize: 16,
  fontWeight: 600,
  lineHeight: "22px",
  color: WY_COLORS.nuetralGrey100,
});
export const StyledFAQSubTitle = styled.div({
  fontSize: 14,
  fontWeight: 400,
  lineHeight: "18px",
  color: WY_COLORS.nuetralGrey700,
});
export const StyledFlexColumnContainer = styled.div({
  display: "flex",
  flexDirection: "column",
});
export const StyledFAQContainer = styled.div({
  display: "flex",
  flexDirection: "column",
  padding: 0,
  width: "100%",
  flex: 1,
  justifyContent: "flex-start",
  "@media only screen and (min-width: 1025px)": {
    padding: "0 100px",
    width: "80%",
  },
});
export const StyledAccordionContainer = styled.div({
  border: "0.5px solid #111111",
  borderWidth: "0.5px 0",
  padding: "22px 0",
});
