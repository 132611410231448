interface CartIconProps {
  color?: string;
  width?: number;
  height?: number;
}

export const CartIcon: React.FC<CartIconProps> = ({
  color = "#FF5824",
  width = 16,
  height = 16,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.8327 15C11.477 15 11.9993 14.4777 11.9993 13.8334C11.9993 13.189 11.477 12.6667 10.8327 12.6667C10.1884 12.6667 9.66602 13.189 9.66602 13.8334C9.66602 14.4777 10.1884 15 10.8327 15Z"
        fill={color}
      />
      <path
        d="M5.50065 15C6.14498 15 6.66732 14.4777 6.66732 13.8334C6.66732 13.189 6.14498 12.6667 5.50065 12.6667C4.85632 12.6667 4.33398 13.189 4.33398 13.8334C4.33398 14.4777 4.85632 15 5.50065 15Z"
        fill={color}
      />
      <path
        d="M3.22732 2.62665L3.09398 4.25998C3.06732 4.57331 3.31398 4.83331 3.62732 4.83331H13.834C14.114 4.83331 14.3473 4.61998 14.3673 4.33998C14.454 3.15998 13.554 2.19998 12.374 2.19998H4.18065C4.11398 1.90665 3.98065 1.62665 3.77398 1.39331C3.44065 1.03998 2.97398 0.833313 2.49398 0.833313H1.33398C1.06065 0.833313 0.833984 1.05998 0.833984 1.33331C0.833984 1.60665 1.06065 1.83331 1.33398 1.83331H2.49398C2.70065 1.83331 2.89398 1.91998 3.03398 2.06665C3.17398 2.21998 3.24065 2.41998 3.22732 2.62665Z"
        fill={color}
      />
      <path
        d="M13.6727 5.83331H3.44605C3.16605 5.83331 2.93938 6.04665 2.91272 6.31998L2.67272 9.21998C2.57938 10.36 3.47272 11.3333 4.61272 11.3333H12.0261C13.0261 11.3333 13.9061 10.5133 13.9794 9.51331L14.1994 6.39998C14.2261 6.09331 13.9861 5.83331 13.6727 5.83331Z"
        fill={color}
      />
    </svg>
  );
};
