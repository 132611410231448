interface ShopIconProps {
  color?: string;
  width?: number;
  height?: number;
}

export const ShopIcon: React.FC<ShopIconProps> = ({
  color = "white",
  width = 16,
  height = 16,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.9064 5.51333L14.7131 3.66666C14.4331 1.65333 13.5198 0.833328 11.5664 0.833328H9.9931H9.00643H6.97977H5.9931H4.3931C2.4331 0.833328 1.52643 1.65333 1.23977 3.68666L1.05977 5.51999C0.9931 6.23333 1.18643 6.92666 1.60643 7.46666C2.1131 8.12666 2.8931 8.49999 3.75977 8.49999C4.59977 8.49999 5.40643 8.07999 5.9131 7.40666C6.36643 8.07999 7.13977 8.49999 7.99977 8.49999C8.85977 8.49999 9.6131 8.09999 10.0731 7.43333C10.5864 8.09333 11.3798 8.49999 12.2064 8.49999C13.0931 8.49999 13.8931 8.10666 14.3931 7.41333C14.7931 6.87999 14.9731 6.20666 14.9064 5.51333Z"
        fill={color}
      />
      <path
        d="M7.56675 11.1067C6.72008 11.1933 6.08008 11.9133 6.08008 12.7667V14.5933C6.08008 14.7733 6.22674 14.92 6.40674 14.92H9.58674C9.76674 14.92 9.91341 14.7733 9.91341 14.5933V13C9.92008 11.6067 9.10008 10.9467 7.56675 11.1067Z"
        fill={color}
      />
      <path
        d="M14.2463 9.60001V11.5867C14.2463 13.4267 12.7529 14.92 10.9129 14.92C10.7329 14.92 10.5863 14.7733 10.5863 14.5933V13C10.5863 12.1467 10.3263 11.48 9.8196 11.0267C9.37293 10.62 8.76626 10.42 8.01293 10.42C7.84626 10.42 7.6796 10.4267 7.4996 10.4467C6.31293 10.5667 5.41293 11.5667 5.41293 12.7667V14.5933C5.41293 14.7733 5.26626 14.92 5.08626 14.92C3.24626 14.92 1.75293 13.4267 1.75293 11.5867V9.61334C1.75293 9.14667 2.21293 8.83334 2.64626 8.98667C2.82626 9.04667 3.00626 9.09334 3.19293 9.12001C3.27293 9.13334 3.3596 9.14667 3.4396 9.14667C3.54626 9.16001 3.65293 9.16667 3.7596 9.16667C4.53293 9.16667 5.29293 8.88001 5.89293 8.38667C6.46626 8.88001 7.21293 9.16667 7.9996 9.16667C8.79293 9.16667 9.52626 8.89334 10.0996 8.40001C10.6996 8.88667 11.4463 9.16667 12.2063 9.16667C12.3263 9.16667 12.4463 9.16001 12.5596 9.14667C12.6396 9.14001 12.7129 9.13334 12.7863 9.12001C12.9929 9.09334 13.1796 9.03334 13.3663 8.97334C13.7996 8.82667 14.2463 9.14667 14.2463 9.60001Z"
        fill={color}
      />
    </svg>
  );
};
