import {
  StyledColoredText,
  StyledColoredTextDescription,
  StyledDescription,
  StyledDoubleGridContainer,
  StyledDoubleGridDescriptionContainer,
  StyledGridContainer,
  StyledGridSubTitle,
  StyledGridTitle,
  StyledHeadingContainer,
  StyledPageContainer,
  StyledSectionContainer,
  StyledTitle,
} from "../../../constants/styles";
import TitleSubtitleDescriptionComponent from "../../../components/shared/TitleSubtitleDescriptionComponent/TitleSubtitleDescriptionComponent";
import { CartIcon } from "../../../assets/images/CartIcon";
import { useScreenWidth } from "../../../hooks/useScreenWidth";
import { AppButton } from "../../../components/AppButton/AppButton";
import { LikeIcon } from "../../../assets/images/LikeIcon";
import { StyledHeadingDescription } from "../../AboutUsPage/AboutUsPage.styles";
import DecoratedText from "../../../components/DecoratedText/DecoratedText";
import { InfoIcon } from "../../../assets/images/InfoIcon";
import {
  StyledFAQSubTitle,
  StyledFAQTitle,
} from "../../FAQPage/FAQPage.styles";
import MarketPlaceFeaturesMobile from "./MarketPlaceFeaturesMobile";
import MarketPlaceFeaturesWeb from "./MarketPlaceFeaturesWeb";

export default function MarketPlacePage() {
  const screenWidth = useScreenWidth();

  return (
    <StyledPageContainer>
      <StyledSectionContainer
        style={{
          flexDirection: "column",
          gap: 28,
          alignItems: "flex-start",
        }}
      >
        <TitleSubtitleDescriptionComponent
          IconComponent={CartIcon}
          titleSpan="Dream Big. Sell Bigger!"
          smallTitle="we.yan Marketplace"
          subTitle="we.yan's marketplace is a vibrant digital hub that simplifies the entire e-commerce process for YOU. By integrating cutting-edge payment systems and innovative communication tools, a one-stop shop where your storefront management and interaction with customers becomes a breeze."
          containerStyle={{
            paddingTop: screenWidth && screenWidth <= 1025 ? "0%" : 104,
          }}
        />
        {/* <AppButton
          buttonLevel="secondary"
          customStyles={{
            alignSelf: "flex-start",
            width: 210,
            height: 36,
            fontSize: 12,
            backgroundColor: "black",
            display: "flex",
            flexDirection: "row",
            gap: 8,
            alignItems: "center",
          }}
        >
          <LikeIcon /> Become a we.yan merchant
        </AppButton> */}
      </StyledSectionContainer>
      {screenWidth && screenWidth <= 1025 ? (
        <MarketPlaceFeaturesMobile />
      ) : (
        <MarketPlaceFeaturesWeb />
      )}

      <StyledColoredTextDescription>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <StyledTitle>we.yan Wallet for</StyledTitle>
          <StyledTitle>
            next-gen <DecoratedText text=" businesses" />
          </StyledTitle>
        </div>
        <StyledDescription>
          Streamline your merchant business transactions and customer payments
          with a connected bank account and wallet.
          {/* <AppButton
            buttonLevel="secondary"
            customStyles={{
              alignSelf: "flex-start",
              width: 175,
              height: 36,
              fontSize: 12,
              backgroundColor: "black",
              display: "flex",
              flexDirection: "row",
              gap: 8,
              alignItems: "center",
            }}
          >
            <LikeIcon /> Get a business wallet
          </AppButton> */}
        </StyledDescription>
      </StyledColoredTextDescription>
      <StyledGridContainer style={{ backgroundImage: "none" }}>
        <StyledDoubleGridContainer style={{ width: "100%" }}>
          <StyledDoubleGridDescriptionContainer
            style={{
              borderRight:
                screenWidth && screenWidth >= 1025 ? "1px solid #1A1A1A" : 0,
            }}
          >
            <StyledGridTitle>Multi-layered Security Protocols</StyledGridTitle>
            <StyledGridSubTitle>
              Stay secure with our cutting-edge safety measures. Our security
              infrastructure is crafted to meet the highest global security
              standards, ensuring round-the-clock coverage. We utilize digital
              KYC verification for all customers and merchants, plus a
              zero-tolerance policy against fraud, and our "holding cycle" for
              transactions adds an extra layer of protection, allowing merchants
              to confirm delivery before finalizing transactions.
            </StyledGridSubTitle>
            {/* <AppButton
              buttonLevel="secondary"
              customStyles={{
                alignSelf: "flex-start",
                width: 116,
                height: 32,
                fontSize: 12,
                backgroundColor: "black",
                display: "flex",
                flexDirection: "row",
                gap: 8,
                alignItems: "center",
              }}
            >
              <InfoIcon /> Learn more
            </AppButton> */}
          </StyledDoubleGridDescriptionContainer>
          <StyledDoubleGridDescriptionContainer>
            <StyledGridTitle>Drive better Customer outcomes</StyledGridTitle>
            <StyledGridSubTitle>
              Do more with conversations. Go past just making sales to building
              actual customer relationships. You can communicate with customers
              directly, discuss specific products, use polling to get customer
              feedback and enhance customer service all within we.yan.
            </StyledGridSubTitle>
          </StyledDoubleGridDescriptionContainer>
        </StyledDoubleGridContainer>
      </StyledGridContainer>
      <StyledHeadingContainer
        style={{
          alignItems: "flex-start",
          marginBottom: screenWidth && screenWidth >= 1025 ? 150 : 50,
        }}
      >
        <StyledColoredText style={{ textAlign: "left" }}>
          FAQs about we.yan marketplace.
        </StyledColoredText>
        <StyledHeadingDescription>
          <StyledFAQTitle>
            How do I set up a merchant shop on we.yan?
          </StyledFAQTitle>
          <StyledFAQSubTitle>
            Merchants can set up shop fronts on the we.yan platform to sell
            products and services directly.
          </StyledFAQSubTitle>
          <StyledFAQTitle>
            What products can I sell on the we.yan marketplace?
          </StyledFAQTitle>
          <StyledFAQSubTitle>
            You can sell a variety of products and services, provided they
            comply with we.yan's terms of service.
          </StyledFAQSubTitle>
          <StyledFAQTitle>
            Can I customize the look of my shop front on we.yan?
          </StyledFAQTitle>
          <StyledFAQSubTitle>
            Yes, merchants can customize their shop fronts to reflect their
            brand and style.
          </StyledFAQSubTitle>

          {/* <AppButton
            buttonLevel="secondary"
            customStyles={{
              alignSelf: "flex-start",
              width: 116,
              height: 32,
              fontSize: 12,
              backgroundColor: "black",
              display: "flex",
              flexDirection: "row",
              gap: 8,
              alignItems: "center",
            }}
          >
            <InfoIcon /> Learn more
          </AppButton> */}
        </StyledHeadingDescription>
      </StyledHeadingContainer>
    </StyledPageContainer>
  );
}
