import { WY_IMAGES } from "../../constants/images";

export const MOBILE_OPTIONS = [
  { title: "Personal", value: "personal" },
  { title: "Business", value: "business" },
  { title: "About Us", value: "aboutUs" },
  { title: "FAQ", value: "faq" },
];

export const WEB_OPTIONS = [
  { title: "Features", value: "features" },
  { title: "Personal", value: "personal" },
  { title: "Business", value: "business" },
  { title: "About Us", value: "aboutUs" },
  { title: "FAQ", value: "faq" },
];

export const FEATURES = [
  {
    icon: WY_IMAGES.receiptItem,
    title: "Payments",
    value: "payments",
    description:
      "Safe, easy, fun transactions with integrated social enablements",
  },
  {
    icon: WY_IMAGES.emptyWallet,
    title: "Wallets",
    value: "wallets",
    description: "Hassle-free, easy-to-set up creative way to manage money",
  },
  {
    icon: WY_IMAGES.messageFavorite,
    title: "Chats",
    value: "chats",
    description: "End-to-end encrypted technology & bespoke abilities.",
  },
  {
    icon: WY_IMAGES.shoppingCart,
    title: "Marketplace",
    value: "marketPlace",
    description: "One-stop shop with innovative chat & payment technologies",
  },
];
