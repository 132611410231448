import AppBurgerMenu from "../../AppBurgerMenu/AppBurgerMenu";
import AppAccordion from "../../AppAccordion/AppAccordion";
import { WY_COLORS } from "../../../constants/app-colors";
import {
  StyledHeaderContainer,
  StyledFeaturesContainer,
  StyledFeatureTitleIconDescription,
  StyledFeatureTitleDescription,
  StyledWeyanBlackBackgroundImage,
} from "./MobileHeader.styles";
import { FEATURES, MOBILE_OPTIONS } from "../Header.constants";
import { useNavigate, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import { PAGES_ROUTES } from "../../../constants/pages-routes";
import { WeyanWordImage } from "../../../assets/images/WeyanWordImage";

export const MobileHeader = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState<string>("");

  useEffect(() => {
    const currentPath = location.pathname;
    const matchingOption = MOBILE_OPTIONS.find((option) =>
      currentPath.includes(PAGES_ROUTES[option.value])
    );
    if (matchingOption) {
      setSelectedOption(matchingOption.value);
    } else {
      setSelectedOption("");
    }
  }, [location.pathname]);

  const handleNavigate = (route: string, value: string) => {
    setSelectedOption(value);
    navigate(route);
    setMenuOpen(false);
  };

  return (
    <StyledHeaderContainer>
      <div
        style={{ cursor: "pointer", display: "flex" }}
        onClick={() => navigate(PAGES_ROUTES.home)}
      >
        <WeyanWordImage width={62} height={16} />
      </div>
      {/* <AppButton
        customStyles={{
          height: 32,
          width: 96,
          fontSize: 12,
          color: "white",
          whiteSpace: "nowrap",
          border: "1px solid rgba(134, 143, 151, 0.4)",
          background: "linear-gradient(0deg, #0A0A0A, #0A0A0A), #000000",
          "&:hover": {
            backgroundColor: "unset",
          },
        }}
        buttonLevel="secondary"
      >
        Get we.yan
      </AppButton> */}
      <AppBurgerMenu isOpen={isMenuOpen} setMenuOpen={setMenuOpen}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 40,
          }}
        >
          <AppAccordion title="Features">
            <StyledFeaturesContainer>
              {FEATURES.map((feature) => {
                return (
                  <StyledFeatureTitleIconDescription
                    key={feature.title}
                    onClick={() =>
                      handleNavigate(
                        PAGES_ROUTES.features[feature.value],
                        feature.value
                      )
                    }
                  >
                    <img style={{ width: 16, height: 16 }} src={feature.icon} />
                    <StyledFeatureTitleDescription>
                      <div>{feature.title}</div>
                      <div
                        style={{
                          fontSize: 14,
                          color: WY_COLORS.whiteWithOpacityTwo,
                        }}
                      >
                        {feature.description}
                      </div>
                    </StyledFeatureTitleDescription>
                  </StyledFeatureTitleIconDescription>
                );
              })}
            </StyledFeaturesContainer>
          </AppAccordion>
          {MOBILE_OPTIONS.map((option) => (
            <div
              style={{
                color:
                  option.value === selectedOption
                    ? WY_COLORS.primaryBrightBlue300
                    : WY_COLORS.whiteWithOpacityTwo,
                paddingLeft: 16,
                cursor: "pointer",
              }}
              key={option.value}
              onClick={() =>
                handleNavigate(PAGES_ROUTES[option.value], option.value)
              }
            >
              {option.title}
            </div>
          ))}
        </div>
      </AppBurgerMenu>
    </StyledHeaderContainer>
  );
};
