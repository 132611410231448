import { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import firebase from "firebase/compat/app";
import { firebaseConfig } from "./services/firebase/config";
import { Header } from "./components/Header/Header";
import { Footer } from "./components/Footer/Footer";
import { MainContent, StyledAppWrapperContainer } from "./App.styles";
import { HomePage } from "./pages/HomePage/HomePage";
import { PAGES_ROUTES } from "./constants/pages-routes";
import AboutUsPage from "./pages/AboutUsPage/AboutUsPage";
import BusinessPage from "./pages/BusinessPage/BusinessPage";
import PersonalPage from "./pages/PersonalPage/PersonalPage";
import FAQPage from "./pages/FAQPage/FAQPage";
import MarketPlacePage from "./pages/FeaturePages/MarketPlacePage/MarketPlacePage";
import WalletPage from "./pages/FeaturePages/WalletPage/WalletPage";
import { ScrollToTop } from "./components/AppScrollTop/AppScrollTop";
import ChatPage from "./pages/ChatPage/ChatPage";
import PaymentPage from "./pages/PaymentPage/PaymentPage";

function App() {
  useEffect(() => {
    firebase.initializeApp(firebaseConfig).firestore();
  }, []);

  return (
    <StyledAppWrapperContainer>
      <Router>
        <ScrollToTop />
        <Header />
        <MainContent>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path={PAGES_ROUTES.home} element={<HomePage />} />
            <Route path={PAGES_ROUTES.aboutUs} element={<AboutUsPage />} />
            <Route path={PAGES_ROUTES.business} element={<BusinessPage />} />
            <Route path={PAGES_ROUTES.personal} element={<PersonalPage />} />
            <Route path="*" element={<Navigate to="/" />} />{" "}
            <Route path={PAGES_ROUTES.faq} element={<FAQPage />} />
            <Route
              path={PAGES_ROUTES.features.marketPlace}
              element={<MarketPlacePage />}
            />
            <Route
              path={PAGES_ROUTES.features.wallets}
              element={<WalletPage />}
            />
            <Route path={PAGES_ROUTES.features.chats} element={<ChatPage />} />
            <Route
              path={PAGES_ROUTES.features.payments}
              element={<PaymentPage />}
            />
          </Routes>
        </MainContent>
        <Footer />
      </Router>
    </StyledAppWrapperContainer>
  );
}

export default App;
