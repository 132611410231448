interface ChatIconProps {
  color?: string;
  width?: number;
  height?: number;
}

export const ChatIcon: React.FC<ChatIconProps> = ({
  color = "#3CE5FF",
  width = 16,
  height = 16,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.0197 5.33331C9.71968 5.33331 8.66634 4.27998 8.66634 2.97998V0.99998C8.66634 0.633313 8.36634 0.333313 7.99967 0.333313H3.66634C1.82634 0.333313 0.333008 1.81998 0.333008 3.65331V8.29998C0.333008 10.14 1.82634 11.6333 3.66634 11.6333H4.66634C4.85301 11.6333 5.09301 11.7533 5.19967 11.9L6.19967 13.2266C6.63967 13.8133 7.35967 13.8133 7.79967 13.2266L8.79967 11.9C8.92634 11.7333 9.12634 11.6333 9.33301 11.6333H10.3397C12.1797 11.6333 13.6663 10.1466 13.6663 8.30665V5.99998C13.6663 5.63331 13.3663 5.33331 12.9997 5.33331H11.0197ZM4.33301 6.99998C3.95967 6.99998 3.66634 6.69998 3.66634 6.33331C3.66634 5.96665 3.95967 5.66665 4.33301 5.66665C4.69967 5.66665 4.99967 5.96665 4.99967 6.33331C4.99967 6.69998 4.70634 6.99998 4.33301 6.99998ZM6.99967 6.99998C6.62634 6.99998 6.33301 6.69998 6.33301 6.33331C6.33301 5.96665 6.62634 5.66665 6.99967 5.66665C7.36634 5.66665 7.66634 5.96665 7.66634 6.33331C7.66634 6.69998 7.37301 6.99998 6.99967 6.99998Z"
        fill={color}
      />
    </svg>
  );
};
