import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { WY_COLORS } from "../../constants/app-colors";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useScreenWidth } from "../../hooks/useScreenWidth";

interface AppAccordionProps {
  title: string;
  children: React.ReactNode;
  faq?: boolean;
}

const AppAccordion: React.FC<AppAccordionProps> = ({
  title,
  children,
  faq,
}) => {
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const screenWidth = useScreenWidth();

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <Accordion
      expanded={expanded === "panel1"}
      onChange={handleChange("panel1")}
      sx={{
        backgroundColor: "black",
        color: "white",
        display: "flex",
        flexDirection: faq
          ? screenWidth && screenWidth >= 1025
            ? "row"
            : "column"
          : "column",
        alignItems: faq ? "flex-start" : "unset",
        justifyContent: "space-between",
      }}
    >
      <AccordionSummary
        sx={{
          color: WY_COLORS.whiteWithOpacityTwo,
        }}
        expandIcon={
          !faq && (
            <ExpandMoreIcon sx={{ color: WY_COLORS.whiteWithOpacityTwo }} />
          )
        }
        aria-controls="panel1bh-content"
        id="panel1bh-header"
      >
        {faq && (
          <AddCircleIcon
            sx={{
              color: WY_COLORS.white,
              marginRight: "20px",
            }}
          />
        )}
        <div style={faq ? { color: "white" } : {}}>{title}</div>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
};

export default AppAccordion;
