import styled from "styled-components";
import { WY_COLORS } from "../../constants/app-colors";

export const StyledFooterContainer = styled.div({
  display: "flex",
  padding: 20,
  flexDirection: "column",
  gap: 64,
  "@media only screen and (min-width: 1025px)": {
    flexDirection: "row",
    gap: 197,
    marginInline: 80,
    marginBottom: 80,
    justifyContent: "space-around",
  },
  /* Media query for tablets */
  "@media (min-width: 768px) and (max-width: 1024px)": {
    flexDirection: "row",
    gap: 197,
    marginInline: 80,
    marginBottom: 80,
    justifyContent: "space-around",
  },
});

export const StyledImageAndStayUpdatedContainer = styled.div({
  display: "flex",
  flexDirection: "column",
  gap: 20,
});

export const StyledStayUpdated = styled.div({
  display: "flex",
  flexDirection: "column",
  gap: 16,
  padding: "0 8px",
});

export const StyledSocialMediaExploreCompanyContainer = styled.div({
  display: "flex",
  flexWrap: "wrap",
  gap: 60,
  padding: "0 16px",
  whiteSpace: "nowrap",
  "@media only screen and (min-width: 1025px)": {
    flexWrap: "unset",
    // padding: "0 80px",
  },
});

export const StyledSubTitleList = styled.div({
  display: "flex",
  flexDirection: "column",
  gap: 24,
});
export const StyledTitle = styled.div({
  color: WY_COLORS.nuetralGrey700,
  fontSize: 14,
  fontWeight: 400,
  marginBottom: 24,
});

export const StyledSubTitle = styled.div({
  fontSize: 14,
  cursor: "pointer",
  "&:hover": {
    textDecoration: "underline",
  },
});

export const StyledWeyanGifImage = styled.img({
  width: 100,
});
