import styled from "styled-components";
import { WY_COLORS } from "../../../constants/app-colors";

export const StyledShopFeaturesHeader = styled.div<{ isActive?: boolean }>(
  ({ isActive }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: 16,
    borderBottom: "1px solid red",
    paddingBottom: 40,
    fontSize: 16,
    fontWeight: 600,
    lineHeight: "22px",
    color: WY_COLORS.nuetralGrey100,
    width: "fit-content",
    "@media only screen and (min-width: 1025px)": {
      color: isActive ? WY_COLORS.nuetralGrey100 : WY_COLORS.nuetralGrey700,
      borderColor: isActive ? "red" : "rgba(255, 255, 255, 0.05)",

      cursor: "pointer",
      transition: "color 0.3s ease",
      "&:hover": {
        color: WY_COLORS.nuetralGrey100,
        borderColor: "red",
      },
    },
  })
);
export const StyledShopFeaturesDescriptionImageContainer = styled.div<{
  isActive?: boolean;
}>(({ isActive }) => ({
  display: "flex",
  flexDirection: "column",
  marginTop: 80,
  gap: 60,
  "@media only screen and (min-width: 1025px)": {
    display: isActive ? "flex" : "none",
    flexDirection: "row",
    marginTop: isActive ? 80 : 0,
    alignItems: "flex-start",
    transition: "all 0.3s ease-in-out",
    gap: 30,
  },
}));
export const StyledShopFeaturesDescriptionContainer = styled.div({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: 40,
  "@media only screen and (min-width: 1025px)": {
    maxWidth: 400,
  },
});
export const StyledDescriptionContainer = styled.div({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: 16,
});
export const StyledDescriptionTitle = styled.div({
  fontSize: 20,
  fontWeight: 600,
  lineHeight: "24px",
  color: WY_COLORS.nuetralGrey100,
});
export const StyledDescriptionSubTitle = styled.div({
  fontSize: 14,
  fontWeight: 400,
  lineHeight: "22px",
  color: WY_COLORS.whiteWithOpacity,
});
