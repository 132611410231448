interface ClosedWalletIconProps {
  color?: string;
  width?: number;
  height?: number;
}

export const ClosedWalletIcon: React.FC<ClosedWalletIconProps> = ({
  color = "#8287A7",
  width = 16,
  height = 16,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.6655 7.31379V8.68712C14.6655 9.05379 14.3722 9.35379 13.9988 9.36712H12.6922C11.9722 9.36712 11.3122 8.84046 11.2522 8.12046C11.2122 7.70046 11.3722 7.30712 11.6522 7.03379C11.8988 6.78046 12.2388 6.63379 12.6122 6.63379H13.9988C14.3722 6.64712 14.6655 6.94712 14.6655 7.31379Z"
        fill={color}
      />
      <path
        d="M13.6454 10.3663H12.692C11.4254 10.3663 10.3587 9.41301 10.252 8.19967C10.192 7.50634 10.4454 6.81301 10.952 6.31967C11.3787 5.87967 11.972 5.63301 12.612 5.63301H13.6454C13.8387 5.63301 13.9987 5.47301 13.9787 5.27967C13.832 3.65967 12.7587 2.55301 11.1654 2.36634C11.0054 2.33967 10.8387 2.33301 10.6654 2.33301H4.66536C4.4787 2.33301 4.2987 2.34634 4.12536 2.37301C2.42536 2.58634 1.33203 3.85301 1.33203 5.66634V10.333C1.33203 12.173 2.82536 13.6663 4.66536 13.6663H10.6654C12.532 13.6663 13.8187 12.4997 13.9787 10.7197C13.9987 10.5263 13.8387 10.3663 13.6454 10.3663ZM8.66536 6.49967H4.66536C4.39203 6.49967 4.16536 6.27301 4.16536 5.99967C4.16536 5.72634 4.39203 5.49967 4.66536 5.49967H8.66536C8.9387 5.49967 9.16536 5.72634 9.16536 5.99967C9.16536 6.27301 8.9387 6.49967 8.66536 6.49967Z"
        fill={color}
      />
    </svg>
  );
};
