export const WY_COLORS = {
  primaryBlack100: "#7E7E95",
  primaryBlack200: "#5E5E73",
  primaryBlack300: "#5E5E73",
  primaryBlack400: "#1A191E",
  primaryBlack500: "#131418",
  primaryBlack600: "#141415",
  primaryBlack700: "#000000",
  primaryBlack1: "#3C3C47",
  primaryBlack2: "#161515",

  primaryBrightBlue100: "#B1F5FF",
  primaryBrightBlue300: "#63EAFF",
  primaryBrightBlue400: "#3CE5FF",
  primaryBrightBlue700: "#0F3940",

  nuetralGrey100: "#F7F8F8",
  nuetralGrey200: "#F0EFF6",
  nuetralGrey300: "#E4E3ED",
  nuetralGrey400: "#D0CFDE",
  nuetralGrey500: "#B1B0BF",
  nuetralGrey600: "#8D8BA1",
  nuetralGrey700: "#9099A1",

  // green
  accentJade300: "#37B78D",
  accentJade400: "#05A570",
  accentJade500: "#047C54",

  // orange
  accentOrange300: "#FF5824",
  accentOrange400: "#FF7950",

  // red
  red: "#EF6337",
  darkRed: "#A80900",
  accentRed500: "#FF5247",
  accentRed400: "#FF453A",
  accentRed600: "#F00D00",

  customDarkGray: "#1B1C22",
  customVeryDarkGray: "#1A1A1A",
  /**white */
  white: "#ffffff",
  whiteWithOpacity: "rgba(255, 255, 255, 0.8)",
  whiteWithOpacityTwo: "rgba(144, 153, 161, 1)",

  // yellow
  lightYellow: "#FFF7D9",
  darkYellow: "#FFC700",

  // accent
  accentSaffron100: "#FFE3AD",
  accentSaffron300: "#FFC85A",
  accentSaffron700: "#402F0C",

  //linear gradient
  linearGradient: "#02C5C0",
};
