import React from "react";
import styled from "styled-components";
import { WY_IMAGES } from "../../constants/images";

const StyledSpan = styled.span({
  background: `url(${WY_IMAGES.textBg}) center`,
  backgroundSize: "cover",
  WebkitBackgroundClip: "text",
  backgroundClip: "text",
  WebkitTextFillColor: "transparent",
});

interface DecoratedTextProps {
  text: string;
}

const DecoratedText: React.FC<DecoratedTextProps> = ({ text }) => {
  const words = text.split(" ");

  return (
    <>
      {words.map((word, index) => (
        <StyledSpan key={index}>{word} </StyledSpan>
      ))}
    </>
  );
};

export default DecoratedText;
