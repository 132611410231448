import { useScreenWidth } from "../../hooks/useScreenWidth";
import { AppleStoreIcon } from "../../assets/images/AppleStoreIcon";
import { InfoIcon } from "../../assets/images/InfoIcon";
import { PlayIcon } from "../../assets/images/PlayIcon";
import { PlayStoreIcon } from "../../assets/images/PlayStoreIcon";
import { AppButton } from "../../components/AppButton/AppButton";
import DecoratedText from "../../components/DecoratedText/DecoratedText";
import TitleSubtitleDescriptionComponent from "../../components/shared/TitleSubtitleDescriptionComponent/TitleSubtitleDescriptionComponent";
import { WY_IMAGES } from "../../constants/images";
import {
  StyledColoredTextDescription,
  StyledTitle,
  StyledDescription,
  StyledHeadingContainer,
  StyledColoredText,
  StyledGridContainer,
  StyledDoubleGridContainer,
  StyledDoubleGridDescriptionContainer,
  StyledGridTitle,
  StyledGridSubTitle,
  StyledPageContainer,
  StyledSectionContainer,
} from "../../constants/styles";
import {
  StyledHeadingDescription,
  StyledLastSectionContainer,
  StyledStoresLinks,
  StyledStoresLinksContainer,
  StyledStoreLink,
} from "../AboutUsPage/AboutUsPage.styles";
import { StyledFAQTitle, StyledFAQSubTitle } from "../FAQPage/FAQPage.styles";
import { ChatIcon } from "../../assets/images/ChatIcon";
import { ThumbsUpIcon } from "../../assets/images/ThumbsUpIcon";
import { KeyIcon } from "../../assets/images/KeyIcon";
import { PrivacyIcon } from "../../assets/images/PrivacyIcon";
import { StarIcon } from "../../assets/images/StarIcon";
import { PasswordCheckIcon } from "../../assets/images/PasswordCheckIcon";
import { EyeLashIcon } from "../../assets/images/EyeLashIcon";
import { WY_COLORS } from "../../constants/app-colors";
import styled from "styled-components";
import { PaymentIcon } from "../../assets/images/PaymentIcon";

export const StyledBannerImage = styled.img({
  width: 343,
  height: 216,
  objectFit: "cover",
  borderRadius: 16,
  alignSelf: "center",
  "@media only screen and (min-width: 1200px)": {
    width: 1104,
    height: 615,
  },
});

export const StyledPaymentImages = styled.img({
  display: "flex",
  justifySelf: "center",
  marginTop: 20,
  height: 310,
  alignSelf: "center",
  "@media only screen and (min-width: 1025px)": {
    width: 470,
  },
  "@media (min-width: 768px) and (max-width: 1024px)": {
    height: 400,
  },
});

export default function PaymentPage() {
  const screenWidth = useScreenWidth();

  if (screenWidth === 0) {
    return <></>;
  }

  return (
    <StyledPageContainer style={{ gap: 100 }}>
      <StyledSectionContainer
        style={{
          flexDirection: "column",
          gap: 28,
          alignItems: "flex-start",
        }}
      >
        <TitleSubtitleDescriptionComponent
          IconComponent={PaymentIcon}
          titleSpan="Say Hello to the Future"
          titleSpanTwo="of Virtual Payments."
          smallTitle="we.yan Payments"
          subTitle="Whether you’re splitting bills with friends, sending gifts to family, or adding money to your account, we.yan makes payments easy, extra-safe, and fun. With our brand-new chat integration, your social life and finances are perfectly in sync."
        />
        {/* <AppButton
          buttonLevel="secondary"
          customStyles={{
            alignSelf: "flex-start",
            width: 126,
            height: 36,
            fontSize: 12,
            backgroundColor: "black",
            display: "flex",
            flexDirection: "row",
            gap: 8,
            alignItems: "center",
          }}
        >
          <PlayIcon /> How it works
        </AppButton> */}
      </StyledSectionContainer>

      <StyledTitle
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        Your money, limitless.
      </StyledTitle>

      <StyledGridContainer style={{ backgroundImage: "unset" }}>
        <StyledDoubleGridContainer>
          <StyledDoubleGridDescriptionContainer
            style={{
              borderTop: screenWidth >= 1025 ? 0 : "1px solid #1A1A1A",
              borderRight: screenWidth >= 1025 ? "1px solid #1A1A1A" : 0,
            }}
          >
            <StyledGridTitle>Local Payments</StyledGridTitle>
            <StyledGridSubTitle>
              Enjoy the convenience of lightning-fast and hassle-free
              transactions; whether you're splitting bills, paying salaries,
              making payments, or shopping online.
            </StyledGridSubTitle>
            <StyledPaymentImages src={WY_IMAGES.WalletWithTransaction} />
          </StyledDoubleGridDescriptionContainer>
          <StyledDoubleGridDescriptionContainer
            style={{
              borderTop: screenWidth >= 1025 ? 0 : "1px solid #1A1A1A",
            }}
          >
            <StyledGridTitle>Gifting</StyledGridTitle>
            <StyledGridSubTitle>
              we.yan turns every message into a potential present — you can grab
              a sticker, emoji, a voice note or even a video and attach some
              cash to it and boom! you’ve got a gift.
            </StyledGridSubTitle>
            <StyledPaymentImages src={WY_IMAGES.ConverstaionChatting} />
          </StyledDoubleGridDescriptionContainer>
          {/* <StyledDoubleGridDescriptionContainer
            style={{
              borderTop: screenWidth >= 1025 ? 0 : "1px solid #1A1A1A",
              borderRight: screenWidth >= 1025 ? "1px solid #1A1A1A" : 0,
            }}
          >
            <StyledGridTitle>International Payments*</StyledGridTitle>
            <StyledGridSubTitle>
              Experience the future of cross-border payments. Start making
              transactions within Africa or abroad, send money seamlessly
              through chat and enjoy the convenience of shopping in foreign
              currencies using your we.yan wallet with no hidden charges.
            </StyledGridSubTitle>
          </StyledDoubleGridDescriptionContainer>
          <StyledDoubleGridDescriptionContainer
            style={{
              borderTop: screenWidth >= 1025 ? 0 : "1px solid #1A1A1A",
            }}
          >
            <StyledGridTitle>Donations*</StyledGridTitle>
            <StyledGridSubTitle>
              Giving back is a big deal at we.yan. Set up your charity wallet
              and manage your donations your way. It’s all about making a
              difference where it counts, and we make it easy, exciting to do.
            </StyledGridSubTitle>
          </StyledDoubleGridDescriptionContainer>
          <StyledDoubleGridDescriptionContainer
            style={{
              borderTop: screenWidth >= 1025 ? 0 : "1px solid #1A1A1A",
              borderRight: screenWidth >= 1025 ? "1px solid #1A1A1A" : 0,
            }}
          >
            <StyledGridTitle>Group Payments*</StyledGridTitle>
            <StyledGridSubTitle>
              Whether it’s a shared vacation, a surprise party, or just
              splitting utilities, we.yan simplifies group payments. Gather up
              the crew, send out the requests, & get everyone paid up in no
              time. It’s group payments without the headache.
            </StyledGridSubTitle>
          </StyledDoubleGridDescriptionContainer> */}
          {/* <StyledDoubleGridDescriptionContainer
            style={{
              borderTop: screenWidth >= 1025 ? 0 : "1px solid #1A1A1A",
            }}
          >
            <StyledGridTitle>Pooled Funds*</StyledGridTitle>
            <StyledGridSubTitle>
              Ready to support each other in a big way? Our 'we.yan Dayrah' lets
              you and your friends or community pool your resources together in
              one wallet. Set up monthly drops and organize secure debits for
              every member of the group.
            </StyledGridSubTitle>
          </StyledDoubleGridDescriptionContainer> */}
        </StyledDoubleGridContainer>
      </StyledGridContainer>

      <StyledDoubleGridDescriptionContainer
        style={{
          flexDirection: screenWidth && screenWidth >= 1025 ? "row" : "column",
          justifyContent: "space-between",
          borderTop: "unset",
          borderBottom: "unset",
        }}
      >
        <div>
          <StyledTitle style={{ lineHeight: "unset" }}>
            What's a wallet
          </StyledTitle>
          <StyledTitle>
            without a <DecoratedText text="debit card?" />
          </StyledTitle>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 16,
            alignItems: "flex-start",
            maxWidth: screenWidth && screenWidth >= 1025 ? 500 : "100%",
          }}
        >
          <StyledGridSubTitle>
            Get a we.yan virtual card for all your online spending, or request a
            we.yan Visa card and have it delivered to you at no cost. Enjoy
            unparalleled protection and control over your finances.
          </StyledGridSubTitle>
          {/* <AppButton
            buttonLevel="secondary"
            customStyles={{
              alignSelf: "flex-start",
              width: 116,
              height: 32,
              fontSize: 12,
              backgroundColor: "black",
              display: "flex",
              flexDirection: "row",
              gap: 8,
              alignItems: "center",
            }}
          >
            <InfoIcon /> Learn more
          </AppButton> */}
        </div>
      </StyledDoubleGridDescriptionContainer>
    </StyledPageContainer>
  );
}
