import {
  StyledDescriptionContainer,
  StyledDescriptionSubTitle,
  StyledDescriptionTitle,
  StyledShopFeaturesDescriptionContainer,
  StyledShopFeaturesDescriptionImageContainer,
  StyledShopFeaturesHeader,
} from "./MarketPlacePage.styles";
import { ShopFeature } from "./MarketPlaceFeaturesWeb";
import { StyledGridImage } from "../../../constants/styles";

export default function MarketPlaceFeaturesMobile() {
  return (
    <>
      {ShopFeature.map((feature, index) => (
        <div>
          <StyledShopFeaturesHeader>
            <feature.icon />
            {feature.title}
          </StyledShopFeaturesHeader>
          <StyledShopFeaturesDescriptionImageContainer>
            <StyledShopFeaturesDescriptionContainer>
              {feature?.features.map((section) => (
                <StyledDescriptionContainer>
                  <StyledDescriptionTitle>
                    {section.title}
                  </StyledDescriptionTitle>
                  <StyledDescriptionSubTitle>
                    {section.description}
                  </StyledDescriptionSubTitle>
                </StyledDescriptionContainer>
              ))}
            </StyledShopFeaturesDescriptionContainer>
            <StyledGridImage src={feature.image} />
          </StyledShopFeaturesDescriptionImageContainer>
        </div>
      ))}
    </>
  );
}
